import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormField } from '../FormFields/FormFieldsHelpers';
import styles from './FormFields.module.scss';

export default function TextArea(props) {
  const {
    inputProps: {
      label, optional, name,
    },
  } = props;
  return (
    <FormField
      {...props}
      type="textarea"
      render={({ input, error }) => (
        <>
          <label
            htmlFor={name}
            className={`${!optional ? styles['field-required'] : ''}`}
          >
            {label}
          </label>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </>
      )}
    />
  );
}
