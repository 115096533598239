import React from 'react';
import MultipleFields from '../FormFields/MultipleFields/MultipleFields';
import { FormField } from '../FormFields/FormFieldsHelpers';

export default function FlexibleMulti(props) {
  return (
    <FormField
      noLabel
      noErrorHandling
      display="block"
      {...props}
      render={({ input, error }) => (
        <MultipleFields
          {...props}
          hasError={error}
        />
      )}
    />
  );
}
