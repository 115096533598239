/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useRef } from 'react';
import Table from 'antd/lib/table';
import _get from 'lodash/get';
import ListActions from '../ListActions/ListActions';
import CustomButton from '../../Components/UIElements/Button';
import SETTINGS_APIS from '../../APIs/settings_apis';
import LOOKUPS_APIS from '../../APIs/lookups_apis';
import styles from './paymentEffectiveInformationList.module.scss';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { getColumnSearchProps } from '../../Components/table/tablesHelpers';

const PaymentEffectiveInformationList = ({ history }) => {
  const { push } = history;
  const EFFECTIVE_INFORMATION_APIS = SETTINGS_APIS('paymentEffectiveInformation');
  const searchInputRef = useRef();
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [isModified, setIsModified] = useState(true);

  const [services, setServices] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [Payment, setPayment] = useState([{ label: 'السداد الاول', value: 'firstPayment' }, { label: 'السداد الثاني', value: 'secondPayment' }]);
  useEffect(() => {
    const getData = async () => {
      if (!isModified) return;
      setLoading(true);

      const lists = [
        ['services', setServices],
        ['programs', setPrograms],
      ];
      try {
        await Promise.all(
          lists.map(async ([listName, setFn]) => {
            const {
              data: { options },
            } = await LOOKUPS_APIS.getOptions({
              name: listName,
            });
            setFn(options);
          }),
        );

        const {
          data: { options },
        } = await EFFECTIVE_INFORMATION_APIS.getAll();

        setData(options);
        setIsModified(false);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };
    getData();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isModified]);

  useEffect(() => {
    if (data.length) {
      (async () => {
        const mappedData = data.map(({ metaData, value }) => ({
          ...metaData.data,
          value,
        }));
        setDisplayedData(mappedData);
      })();
    } else {
      setDisplayedData([]);
    }
  }, [data]);

  const columns = [
    {
      title: 'البرنامج',
      dataIndex: ['program'],
      ...getColumnSearchProps({
        column: {
          title: 'البرنامج',
          dataIndex: 'program',
        },
        searchInputRef,
      }),
    },
    {
      title: 'الدفع',
      dataIndex: ['payment'],
      ...getColumnSearchProps({
        column: {
          title: 'الدفع',
          dataIndex: 'payment',
        },
        searchInputRef,
      }),
    },
    {
      title: 'الخدمة',
      dataIndex: ['service'],
      ...getColumnSearchProps({
        column: {
          title: 'الخدمة',
          dataIndex: 'service',
        },
        searchInputRef,
      }),
    },
    {
      title: 'الحذف و التعديل',
      render: (text, record, index) => (
        <ListActions
          record={record}
          push={push}
          submitting={submitting}
          setSubmitting={setSubmitting}
          setIsModified={setIsModified}
          apiDeleteMethod={EFFECTIVE_INFORMATION_APIS.delete}
          title="المعلومات الإثرائيه"
          editRoute="Payment-effective-information-settings"
        />
      ),
    },
  ];

  useEffect(() => {
    if (data.length) {
      (async () => {
        const mappedData = data
          .map(({ metaData, value }) => ({ ...metaData.data, value }))
          .map(option => ({
            ...option,
            service: _get(
              services.find(i => i.value === option.service),
              'metaData.arabicName',
              '⛔ deleted ⛔',
            ),
            payment: _get(
              Payment.find(i => i.value === option.payment),
              'label',
              '⛔ deleted ⛔',
            ),
            program: _get(programs.find(
              i => i.value === services.find(i => i.value === option.service).metaData?.program,
            ), 'metaData.arabicName', '⛔ deleted ⛔'),
          }));
        setDisplayedData(mappedData);
      })();
    } else {
      setDisplayedData([]);
    }
  }, [data, programs, services]);

  const Header = () => (
    <div className={styles.header}>
      <div className={styles['page-header-container']}>
        <div className="title-container">
          <h2 className="tableHeadTitle" />
        </div>

        <small className={styles['header-buttons']}>
          <CustomButton type="button" action={() => push('/Payment-effective-information-settings')}>
            اضافة
          </CustomButton>
        </small>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles['card-body']}>
          { error
            ? <ErrorMessage history={history} />
            : (
              <div>

                <Header />
                <div className={styles.table}>
                  <Table
                    pagination={!!data.length}
                    loading={loading}
                    columns={columns}
                    dataSource={displayedData}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default PaymentEffectiveInformationList;
