import React, {
  useState, useEffect, useMemo, useRef, lazy,
} from 'react';
import _get from 'lodash/get';
import _concat from 'lodash/concat';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';
// import styles from '../FormFields.module.scss';
import axios from '../../util/axios-instance';
import { FormField } from '../FormFields/FormFieldsHelpers';
import { formatErrorText, getDataFromApi } from '../../util/helpers';
import InputStyles from './FormFields.module.scss';
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
export default function Select(props) {
  const {
    inputProps, rawSchema, formValues, formProps,
  } = props;
  const {
    name,
    dependentOn,
    uniforms: { allowedValues, options },
    editable,
    hidden,
    optional,
    lazyLoad,
    label,
  } = inputProps;
  const { request, changeFieldValue } = formProps;
  const [selectOptions, setSelectedOpts] = useState([]);
  const [loading, setLoading] = useState(false);
  const parentLastValue = useRef(null);
  useEffect(() => {
    if (!dependentOn && !lazyLoad) {
      const preSelectOptions = _get(request, `clientHooksData.${name.split('.').pop()}`) || options;
      if (preSelectOptions) {
        setSelectedOpts(preSelectOptions);
      }
    }
  }, [dependentOn, lazyLoad, name, options, request]);
  const apiDependencies = _get(lazyLoad, 'apiDependencies', []);
  const dependentOnData = JSON.stringify(
    apiDependencies.reduce(
      (agg, dependency) => ({
        ...agg,
        [dependency]: _get(formValues, dependency),
      }),
      {},
    ),
  );
  useEffect(() => {
    if (dependentOn) {
      const upperLevelSchema = _get(props, 'parentSchema');
      let path;
      if (upperLevelSchema && upperLevelSchema[dependentOn]) {
        path = Object.keys(upperLevelSchema).find(key => key.includes(dependentOn));
      } else {
        path = Object.keys(rawSchema).find(key => key.includes(dependentOn));
      }
      const pathValue = _get(formValues, path);
      if (pathValue === parentLastValue.current) return;
      changeFieldValue(name, undefined);
      setSelectedOpts([]);
      if (pathValue == null) {
        parentLastValue.current = null;
      }
      parentLastValue.current = pathValue;
      const { url } = lazyLoad;
      setLoading(true);
      const cashedParentValue = parentLastValue.current;
      getDataFromApi({
        url, apiDependencies, formValues, rawSchema,
      })
        .then((res) => {
          // check the value of parent in different timelines, before and after "then" excuction
          if (parentLastValue.current === cashedParentValue) {
            setSelectedOpts(res.data);
            const currentOptions = rawSchema[name].uniforms.options.map(
              ({ value }) => value,
            );
            const newOptions = res.data.filter(
              ({ value }) => !currentOptions.includes(value),
            );
            rawSchema[name].uniforms.options = _concat(
              rawSchema[name].uniforms.options,
              newOptions,
            );
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [apiDependencies, changeFieldValue, dependentOn, dependentOnData, formValues, lazyLoad, name, props, rawSchema]);
  useEffect(() => {
    if (!dependentOn && lazyLoad) {
      const { url } = lazyLoad;
      setLoading(true);
      setSelectedOpts([]);
      axios
        .get(url)
        .then((res) => {
          setSelectedOpts(res.data);
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dependentOn, lazyLoad]);
  return (
    <FormField
      {...props}
      noWrapper
      // noErrorHandling
      render={({ input, error }) => {
        const {
          onChange: fmOnChange,
          value: fmValue,
          ...restInputProps
        } = input;
        const selectValue = selectOptions.find(
          ({ value }) => value === fmValue,
        );
        const isSelectDisabled = !editable
           || !selectOptions.length
           || loading
           || (dependentOn && !formValues[dependentOn]);
        return (
          <>
            {/* <div className={`${styles['field-container']} ${styles['inline-field']}`}>
               <WithLabel name={name} wrapperClass={styles.customSelectWrapper} label={label}> */}
            <label
              className={`${!optional ? InputStyles['field-required'] : ''} ${
                 hidden ? InputStyles['label-hidden'] : ''
               }`}
            >
              {label}
            </label>
            <ReactSelect
              isRtl
              isLoading={lazyLoad && loading}
              isSearchable
              isClearable
              isDisabled={isSelectDisabled}
              options={selectOptions}
              noOptionsMessage={() => 'لا توجد بيانات'}
              className={`${styles['custom-select']} ${
                 error && styles.hasError
               }`}
              {...restInputProps}
              classNamePrefix="react-select"
              placeholder=""
              onChange={option => fmOnChange(option ? option.value : undefined)}
              value={selectValue || null}
            />
          </>
        );
      }}
    />
  );
}
