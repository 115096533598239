import _isEmpty from 'lodash/isEmpty';
import React from 'react';

// import { formatErrorText, transformDigitsToEnglish } from '../RequestForm/helpers';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import moment from 'moment';
import _get from 'lodash/get';
import _set from 'lodash/set';
import fp from 'lodash/fp';
import { notification } from 'antd';

// eslint-disable-next-line import/no-cycle
import axios from './axios-instance';

import i18next from '../i18next';
// eslint-disable-next-line import/no-cycle
import { getLookupOptions } from '../FormSchemas/form-dynamic/helpers';

export function removeAuthentication() {
  localStorage.removeItem('userSlug');
  localStorage.removeItem('userId');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('fullName');
  localStorage.removeItem('userData');
}

export const getOptionLabel = (options, value) => _get(options.find(opt => opt.value === value), 'label', '⛔ deleted ⛔');

export const getOptions = (schema, key) => _get(schema[key], 'uniforms.options');

export function handleValidationErrorMapping(details) {
  return details.map((detail) => {
    switch (detail.type) {
    case 'unique': {
      return `${i18next.t(`general.${detail.name}`)} ${i18next.t(
          `validation.${detail.type}`,
      )}`;
    }
    case 'invalid': {
      return `${i18next.t(`general.${detail.name}`)} ${i18next.t(
          `validation.${detail.type}`,
      )}`;
    }
    default:
      return `${i18next.t(`general.${detail.name}`)} ${i18next.t(
        'validation.invalid',
      )}`;
    }
  });
}

export function handleIntegrationErrorMapping(details) {
  return details.map((detail) => {
    switch (detail.message) {
    case 'INVALID_AGE': {
      return i18next.t('validation.invalidAge');
    }
    case 'INVALID_ISSUE_DATE': {
      return i18next.t('validation.invalidIssueDate');
    }
    case 'INVALID_EXPIRATION_DATE': {
      return i18next.t('validation.invalidExpirationDate');
    }
    default:
      return i18next.t('validation.generalError');
    }
  });
}

export function serverErrorMapping(error) {
  return [error];
}
// / to be refactored into conditoional object
// const toArray = (input) => [].concat(input);
const errorListItems = err => <li>{err}</li>;
export const serverErrorUI = fp.compose(
  fp.map(errorListItems),
  // serverErrorMapping
);

export function formatErrorText(error, label = '', inputProps) {
  if (_get(error, 'fromCustomRegex')) return `${label} ${error.msg}`;
  let rest = '';
  if (error === 'minLength') {
    const minEntries = _get(inputProps, 'render.minEntries');
    return `${label} يجب ألا تكون أقل من ${(+minEntries).toLocaleString(
      'ar-EG',
    )}`;
  }
  if (error === 'minChar' || error === 'maxChar') {
    rest = `${(+inputProps.min).toLocaleString(
      'ar-EG',
    )} و ${(+inputProps.max).toLocaleString('ar-EG')} ${i18next.t(
      'general.letter',
    )}`;
  }
  if (error === 'minNumber' || error === 'maxNumber') {
    rest = `${(+inputProps.min).toLocaleString(
      'ar-EG',
    )} و ${(+inputProps.max).toLocaleString('ar-EG')}`;
  }
  return `${label} ${i18next.t(`validation.${error}`)} ${rest}`;
}

export const regExpValidator = (type, value) => {
  const regExList = {
    nationalId: (target) => {
      target = String(target);
      const nationalIdRegEx = /^[23]\d{6}(01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d{5}$/;
      if (!nationalIdRegEx.test(target)) return false;
      const birthDate = target.slice(1, 7);
      return moment(birthDate, 'YYMMDD').isValid();
    },
    phoneNumber: target => /^01[0125]\d{8}$/.test(target),
    password: target => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/.test(target),
    number: target => /^\d+$/.test(target),
    textWithoutSpaces: target => /^\S+$/.test(target),
    atLeastOneChar: target => /[a-zA-Z\p{Script=Arabic}]/u.test(target),
    email: target => isEmail(target),
    alpha: target => /^[\u0621-\u064AA-Za-z]+[\u0621-\u064AA-Za-z ]*$/.test(target),
    alphanumeric: target => /^[A-Za-z0-9]*$/.test(target),
    alphaAr: target => /^[\u0621-\u064A ]*[\u0621-\u064A]+[\u0621-\u064A ]*$/.test(target),
    alphaEn: target => /^[\sa-z]*[a-z]{1}[\sa-z]*$/i.test(target),
    alphaFr: target => /^[\sa-zàâçéèêëîïôûùüÿñæœ']*[a-zàâçéèêëîïôûùüÿñæœ]{1}[\sa-zàâçéèêëîïôûùüÿñæœ']*$/i.test(target),
    default: () => false,
  };
  return (regExList[type] || regExList.default)(value);
};

export const handleVariationValidation = ({
  type,
  min,
  max,
  numberType,
  value,
  name,
  render,
}) => {
  switch (type) {
  case 'String': {
    if (value.length < min) return 'minChar';
    if (value.length > max) return 'maxChar';
    break;
  }
  case 'Number': {
    return (
      (Number(value) < Number(min) && 'minNumber')
        || (Number(value) > Number(max) && 'maxNumber')
    );
  }
  case 'Object': {
    return value && value.length < Number(render.minEntries) && 'minLength';
  }
  default:
    return false;
  }
};

// returns i18next error mapper
// export const handleRegExValidation = ({
//   value, field, regExp, formValues,
// }) => {
//   const {
//     type, min, max, numberType,
//   } = field;
// const validLength = handleVariationValidation({
//   type, min, max, numberType, value,
// });
//   const valid = regExpValidator(field.regExp, value);

//   switch (regExp) {
//     case 'email': {
//       if (!valid) return 'invalidEmail';
//       break;
//     }
//     case 'password': {
//       if (field.name === 'password' && value) {
//         // if (validLength) return validLength;
//         if (!valid) return 'invalidPassword';
//       }
//       // if (field.name === 'confirmPassword' && value !== formValues.password) return 'noMatch';
//       break;
//     }
//     case 'alphaAr': {
//       if (validLength) return validLength;
//       return (!valid) && 'isNotArabic';
//     }
//     case 'alpha': {
//       if (validLength) return validLength;
//       return !(valid) && 'isNotAlpha';
//     }
//     case 'alphanumeric': {
//       if (validLength) return validLength;
//       return !(valid) && 'isNotAlphanumeric';
//     }
//     default:
//       return !value && 'required';
//   }
// };
export const isFloat = n => (+n).toFixed() !== n;

export const applyCondition = (conditionValue, relation, inputDataValue) => {
  const cases = {
    is(value, entryData) {
      return value === entryData;
    },
    isNot(value, entryData) {
      return value !== entryData;
    },
    greaterThan(value, entryData) {
      return Number(entryData) > Number(value);
    },
    lessThan(value, entryData) {
      return Number(entryData) < Number(value);
    },
    contains(value, entryData) {
      return (entryData || '').includes(value);
    },
    startsWith(value, entryData) {
      return (entryData || '').startsWith(value);
    },
    endsWith(value, entryData) {
      return (entryData || '').endsWith(value);
    },
    default() {
      return false;
    },
  };
  return (cases[relation] || cases.default)(conditionValue, inputDataValue);
};

export const checkConditions = (data, conditions) => conditions.map((condition) => {
  const { fieldName, relation, value } = condition;
  const isValid = applyCondition(value, relation, _get(data, fieldName));
  return { isValid, message: !isValid && `${fieldName} is not valid` };
});

export const validateConditionalFields = (fieldSchema, formValues) => {
  const {
    conditionalField: { conditions, behavior, operator },
  } = fieldSchema;
  const conditionsResult = checkConditions(formValues, conditions);
  let overallValid;
  if (operator === 'and') {
    overallValid = conditionsResult.every(condition => condition.isValid);
  } else {
    overallValid = conditionsResult.some(condition => condition.isValid);
  }
  if (
    (behavior === 'hide' && overallValid)
    || (behavior === 'show' && !overallValid)
  ) {
    return false;
  }
  return true;
};

export const handleCustomRegex = (customRegex, value) => {
  // [ [error msg,  [regex, flags]] ]
  const errorDetails = Object.entries(customRegex).find(
    ([msg, regex]) => !new RegExp(...regex).test(value),
  );
  if (errorDetails) return errorDetails[0];
};

export const showNotification = ({ type, message, description }) => {
  notification[type]({
    message,
    description,
    placement: 'bottomRight',
    duration: 3,
  });
};
export const getDataFromApi = ({ url, apiDependencies = [], formValues }) => {
  const dependentOnData = apiDependencies.reduce(
    (agg, dependency) => ({ ...agg, [dependency]: formValues[dependency] }),
    {},
  );
  return axios.get(url, { params: { where: dependentOnData } });
};

export const getFormDefaults = ({ fieldsSchema }, request = {}) => {
  const stepSchema = Object.entries(fieldsSchema || {}).reduce(
    (base, [name, { defaultValue, uuid, type }]) => {
      if (type === 'price') return { ...base, [name]: 1 };
      if (defaultValue != null) return _set(base, name, defaultValue);
      if (request[uuid] != null) return _set(base, name, request[uuid]);
      const fromClientHooksArray = _get(
        request,
        `clientHooksData.${name}.0.value`,
      );
      if (fromClientHooksArray != null) {
        return _set(base, name, fromClientHooksArray);
      }
      const fromClientHooks = _get(request, `clientHooksData.${uuid}`);
      if (fromClientHooks != null) return _set(base, name, fromClientHooks);
      return base;
    },
    {},
  );
  return stepSchema;
};

export function generateUniqueCachePath(uuid, depsArray, formValues) {
  return `${uuid}&${depsArray.map(dep => _get(formValues, dep)).join('&')}`;
}

function getChildren(dep, schema) {
  const fields = Object.entries(schema);
  const fieldsDependingOnThisDependency = fields.filter(
    ([_, field]) => field.dependentOn === dep,
  );

  return fieldsDependingOnThisDependency.map(([key, _]) => key);
}
export function getDependecies(dependency, schema) {
  const children = getChildren(dependency, schema);

  return children.concat(
    children.flatMap(child => getDependecies(child, schema)),
  );
}

export function NationalitiesNumberValidation({ condidacyLimit, maxNationalityNumber }, setSubmitting, setLoading) {
  const totalNationalitiesNumber = maxNationalityNumber.reduce((acc, curr) => acc + Number(curr.total), 0);
  if (Number(totalNationalitiesNumber) > condidacyLimit) {
    setSubmitting(false);
    setLoading(false);
    return true;
  }
}

export async function studyStagesValidation({ studyStages }, setSubmitting, setLoading, message) {
  const currentDate = moment(new Date());
  const allStudyStages = await getLookupOptions({ name: 'studyStages' });
  const { metaData: { date: [startDate, endDate] } } = allStudyStages.find(({ value }) => value === studyStages);
  if (currentDate >= moment(startDate) && moment(endDate) >= currentDate) {
    return true;
  }
}
