import React from 'react';
import { FormField } from '../FormFields/FormFieldsHelpers';
import styles from './FormFields.module.scss';

export default function TextArea(props) {
  const {
    inputProps: {
      label, optional, name,
    },
  } = props;
  return (
    <FormField
      {...props}
      type="textarea"
      render={({ input, error }) => (
        <>
          <label
            htmlFor={name}
            className={`${!optional ? styles['field-required'] : ''}`}
          >
            {label}
          </label>
          <textarea
            {...input}
            id={name}
            rows="5"
            onKeyDown={e => e.key === 'Enter' && e.stopPropagation()}
            className={`${styles.input} ${styles.textarea}`}
          />
        </>
      )}
    />
  );
}
