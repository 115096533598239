import React from 'react';
import Result from 'antd/lib/result';
import CustomButton from '../UIElements/Button';

const ErrorMessage = ({ history: { push, location } }) => (
  <Result
    status="error"
    title="حدث خطأ أثناء التنفيذ"
    extra={[
      <CustomButton
        type="button"
        secondary
        midWidth
        action={() => push(location.pathname)}
      >
        المحاوله مرة أخرى
      </CustomButton>,
    ]}
  />
);
export default ErrorMessage;
