import React from 'react';
import { Field } from 'react-final-form';
import { FormField } from '../FormFields/FormFieldsHelpers';
import styles from '../FormFields/FormFields.module.scss';
import FormInput from '../FormFields/FormInput';

export default function NumberInput(props) {
  const { inputProps } = props;
  return (
    <FormField
      {...props}
      type="Number"
      render={props => <FormInput props={props} label={inputProps.label} />}
    />
  );
}
