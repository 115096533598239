import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
// import CERTIFICATE_CONFIG_APIS from '../../APIs/certificate_config_api';

import moment from 'moment';
import styles from './ListActions.module.scss';
import { showNotification, studyStagesValidation } from '../../util/helpers';
import { getLookupOptions } from '../../FormSchemas/form-dynamic/helpers';

const ListActions = ({
  push,
  record,
  submitting,
  setSubmitting,
  setIsModified,
  apiDeleteMethod,
  title,
  editRoute,
  noDelete = false,
  form = '',
  deleteItemFromTable,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteHandler = async () => {
    const { value } = record;
    try {
      if (form === 'candidacyLimitForm') {
        const { studyStagesValue: studyStages } = record;
        let studyStageValidation;
        if (studyStages) {
          studyStageValidation = await studyStagesValidation({ studyStages }, setSubmitting, setDeleteModal);
        }

        if (studyStageValidation) {
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: 'لا يمكن الخذف اثناء المرحلة الدراسية',
          });
        }
      }
      const result = await apiDeleteMethod({ value });

      if (result.data.success) {
        showNotification({
          type: 'success',
          message: 'تمت العملية بنجاح',
        });
        setIsModified(true);
        setDeleteModal(false);
        setSubmitting(false);
        if (form === 'candidacyLimitForm') {
          deleteItemFromTable(value);
        }
      }
    } catch (e) {
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: e?.response?.data?.message || 'حدث خطأ',
      });
      setDeleteModal(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <EditOutlined
        onClick={() => {
          push(`/${editRoute}`, { record });
        }}
        className={styles.icon}
      />
      {
        noDelete ? ''
          : (
            <DeleteOutlined
              onClick={() => {
                setDeleteModal(true);
              }}
              className={styles.icon}
            />
          )
      }

      <Modal
        title={`حذف من ${title}`}
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        confirmLoading={submitting}
        footer={
          submitting ? null : (
            <Button onClick={deleteHandler} className={styles.submitDelete}>
              تأكيد
            </Button>
          )
        }
      >
        {submitting ? (
          <div className={styles.spinner}>
            <Spin
              size="large"
              tip="برجاء الانتظار لحين الانتهاء من الحذف ..."
            />
          </div>
        ) : (
          <div>هل انت متاكد من حذف هذا السجل ؟</div>
        )}
      </Modal>
    </>
  );
};

export default ListActions;
