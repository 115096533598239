import React from 'react';

import styles from './Spinner.module.scss';

export default ({ spinnerText, type, buttonSpinner }) => (
  <div className={`${styles['spinner-wrapper']} ${type === 'fullscreen' && styles['spinner-fullscreen']}`}>
    <div className={buttonSpinner ? styles['button-spinner'] : styles.spinner}>
      <h4>{spinnerText}</h4>
    </div>
  </div>
);
