import React from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import DatePicker from 'antd/lib/date-picker';
import Select from 'antd/lib/select';
import InputNumber from 'antd/lib/input-number';
import Config from '../../util/ConfigProvider';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Fields = (props) => {
  const { schemaItem, key, item } = props;
  const {
    type,
    label,
    optional,
    options,
    validators = [],
    extra,
    defaultValue,
    metaData,
    editable = true,
    editableStartYear = true,
    editableEndYear = true,
  } = schemaItem;

  if (type === 'String') {
    return (
      <Form.Item
        label={label}
        name={key}
        rules={[
          {
            required: true,
            message: `برجاء ادخال ${label}!`,
          },
          ...validators,
        ]}
      >
        <Input />
      </Form.Item>
    );
  }
  if (type === 'Number') {
    return (
      <Form.Item
        label={label}
        name={key}
        initialValue={defaultValue}
        rules={[
          {
            required: !optional,
            message: `برجاء ادخال ${label}!`,
          },
          ...validators,
        ]}
      >
        <InputNumber disabled={!editable} />
      </Form.Item>
    );
  }
  if (type === 'TextArea') {
    return (
      <Form.Item
        label={label}
        name={key}
        rules={[
          {
            required: true,
            message: `برجاء ادخال ${label}!`,
          },
          ...validators,
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
    );
  }

  if (type === 'Date') {
    return (
      <Form.Item
        name={key}
        label={label}
        rules={[
          {
            required: !optional,
            message: `برجاء ادخال ${label}!`,
          },
          ...validators,
        ]}
      >
        <DatePicker disabled={!editable} {...extra} />
      </Form.Item>
    );
  }

  if (type === 'RangeDate') {
    return (
      <Form.Item
        name={key}
        label={label}
        rules={[
          {
            required: true,
            message: `برجاء ادخال ${label}!`,
          },
          ...validators,
        ]}
      >
        {!editableStartYear || !editableEndYear ? (
          <RangePicker
            {...extra}
            placeholder={['من', 'الى']}
            disabled={[!editableStartYear, !editableEndYear]}
          />
        ) : (
          <RangePicker
            disabled={!editable && item?.year}
            {...extra}
            placeholder={['من', 'الى']}
          />
        ) }

      </Form.Item>
    );
  }

  if (type === 'Select') {
    return (
      <Config>
        <Form.Item
          name={key}
          label={label}
          rules={[
            {
              required: !optional,
              message: `برجاء اختيار ${label}!`,
            },
          ]}
        >
          <Select
            placeholder={`اختر ${label}`}
            allowClear
            defaultValue={defaultValue}
            value={defaultValue}
            showSearch
            disabled={!editable}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {options.map((option) => {
              const { value, label: optionLabel } = option;
              return <Option value={value}>{optionLabel}</Option>;
            })}
          </Select>
        </Form.Item>
      </Config>
    );
  }
  if (type === 'header') {
    return (
      <h3>
        {schemaItem?.metaData?.label}
      </h3>
    );
  }
};

export default Fields;
