import moment from 'moment';
import LOOKUPS_APIS from '../APIs/lookups_apis';

export async function generalAdmissionSettingsEditValidation(item) {
  if (['editNominationPaymentDeadline', 'editNominationDataDeadline'].includes(item?.value)) {
    const editNominationHigherEducation = (
      await LOOKUPS_APIS.getOptions({
        category: 'lookups',
        name: 'servicesOpeningClosingTimes',
      })
    )?.data?.options?.find(({ metaData: { service } }) => service === 'editNominationHigherEducation');

    if (editNominationHigherEducation) {
      let [startDate, endDate] = editNominationHigherEducation?.metaData?.date;
      startDate = moment(startDate).format('YYYY/MM/DD');
      endDate = moment(endDate).format('YYYY/MM/DD');
      const currentDate = moment().format('YYYY/MM/DD');

      if (currentDate >= startDate && currentDate <= endDate) {
        return { message: 'لا يمكن تعديل القيمة اثناء مرحلة تعديل ترشيح' };
      }
    }
  }
}

export async function checkIfRequestExistInStudyStage(service, filter) {
  const { count } = await LOOKUPS_APIS.requestsCustomCount(service, filter);

  return count;
}
