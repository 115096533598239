/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState, useLayoutEffect, useMemo, useEffect,
} from 'react';
import { useForm, Form } from 'react-final-form';
import _get from 'lodash/get';
import _match from 'lodash/isMatch';
import _groupBy from 'lodash/groupBy';
import Table from '../../UIElements/Table/Table';
import { TrueIcon, FalseIcon } from '../../table/tablesHelpers';

import useElementsTree from '../useElementsTree';
import styles from './MultipleFields.module.scss';
import {
  formatErrorText,
  getOptionLabel,
  getOptions,
} from '../../../util/helpers';
import FeedbackMessage from '../../UIElements/FeedbackMessage/FeedbackMessage';
import EditAction from './EditAction';

const validateUniqueness = (rows, uniqueCol) => groupKey => (formValues) => {
  if (!uniqueCol.length) return {};

  const values = uniqueCol.reduce((acc, uc) => ({ ...acc, [uc]: formValues[uc] }), {});

  const uniqueError = groupKey
    ? _get(rows, formValues[groupKey], []).some(row => _match(row, values))
    : rows.some(row => _match(row, values));

  if (!uniqueError) return {};

  if (uniqueCol.length === 1) return { [uniqueCol[0]]: 'unique error' };

  return uniqueCol.reduce((acc, uc) => ({ ...acc, [uc]: 'unique error with other field' }), {});
  // return uniqueCol.reduce((acc, uc) => {
  //   const ucValue = formValues[uc];
  //   const uniqueError = groupKey
  //     ? _get(rows, formValues[groupKey], []).some((row) => row[uc] === ucValue)
  //     : rows.some((row) => row[uc] === ucValue);
  //   if (uniqueError) return { ...acc, [uc]: 'unique error' };
  //   return acc;
  // }, {});
};

const validateAlike = (rows, alikeCol) => groupKey => formValues => alikeCol.reduce((acc, uc) => {
  const ucValue = formValues[uc];
  const alikeError = groupKey
    ? _get(rows, formValues[groupKey], []).some(row => row[uc] !== ucValue)
    : rows.some(row => row[uc] !== ucValue);
  if (alikeError) return { ...acc, [uc]: 'alike error' };
  return acc;
}, {});

const errorFactory = message => ({
  response: { data: { details: [{ message }] } },
});
const valuesMap = {
  true: () => <TrueIcon />,
  false: () => <FalseIcon />,
};

const FormComp = ({
  inputProps,
  typeGetter,
  rawSchema,
  formValues,
  formProps,
  handleSubmit,
  values,
  submitSucceeded,
  hasSubmitErrors,
  dirtySinceLastSubmit,
  parentFormRef,
  dirty,
  parentName,
  parentErrors,
  parentSchema,
  ...rest
}) => {
  const {
    render: { maxEntries = Infinity },
  } = inputProps;
  const tableRows = _get(formValues, parentName, []);
  const allowSumition = tableRows.length < maxEntries;
  const fieldsContent = useElementsTree({
    typeGetter,
    rawSchema,
    otherProps: { formValues: { ...formValues, ...values }, parentSchema, formProps },
  });
  const formRef = useForm();
  useLayoutEffect(() => {
    const state = parentFormRef.getFieldState(parentName);
    if (state && state.touched && state.error) parentFormRef.resetFieldState(parentName);
  }, [parentFormRef, parentName, values]);
  useLayoutEffect(() => {
    if (submitSucceeded && !hasSubmitErrors) formRef.reset();
  }, [formRef, submitSucceeded, hasSubmitErrors]);

  return (
    <form
      onSubmit={(e, ...rest) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(e, ...rest);
      }}
    >
      <div className={styles.fields}>{fieldsContent}</div>
      <div className={styles.controllers}>
        {/* eslint-disable-next-line react/button-has-type */}
        {allowSumition
        && (
          <button
            type={allowSumition ? 'submit' : 'button'}
            disabled={!allowSumition}
          >
            إضافة
          </button>
        )}
      </div>
      {/* {parentErrors || (submitErrors && !dirtySinceLastSubmit) ? <FeedbackMessage type="error" message={errorMapper(parentErrors || submitErrors)} /> : null} */}
    </form>
  );
};

export default (props) => {
  const {
    inputProps: input,
    formProps,
    hasError,
    typeGetter,
    rawSchema,
    formValues,
  } = props;
  const {
    schema,
    label,
    handler,
    render: {
      groupBy: renderAsGroup, unique: uniqueCol, alike: alikeCol, maxEntries = Infinity, allowEdit = false, allowDelete = true,
    },
  } = input;
  const {
    slug, serviceDictionary, options, originalStepValues,
  } = formProps;
  // const shouldCollapseForm = input.name.split('.').pop() === 'parties';
  // const [isFormHidden, toggleHide] = useState(shouldCollapseForm);
  const [tableData, setTableData] = useState(
    _get(originalStepValues, input.name, []),
  );
  const formRef = useForm();
  useEffect(() => {
    if (tableData.length) formRef.change(input.name, tableData);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  const [shouldBeVisible, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [error, setError] = useState();
  let tableDataDisplay = [];
  if (tableData.length) {
    const tableRowDictionary = Object.keys(tableData.slice(0, 20).reduce((agg, c) => ({ ...agg, ...c }), {})).reduce((acc, k) => ({
      ...acc,
      [k]: getOptions(schema, k, formValues),
    }), {});
    tableDataDisplay = tableData.map(data => Object.keys(data).reduce((acc, k) => (
      {
        ...acc,
        [k]: tableRowDictionary[k]
          ? getOptionLabel(tableRowDictionary[k], data[k])
          : data[k],
      }), {}));
  }

  async function submitHandler(data) {
    const updatedTableRows = tableData.concat(data);
    formRef.change(input.name, updatedTableRows);
    formRef.resetFieldState(input.name);
    setLoading(true);
    setError(null);
    // return () => {
    // if (tableData.find((item) => Object.values(item).sort().join('') === Object.values(response.data).sort().join(''))) {
    //   return setError(errorFactory('هذا الشخص مُضاف بالفعل'));
    // }
    // if (Object.keys(response.data).length) {
    setTableData(updatedTableRows);
    setVisibility(false);
    // # this is a work around to fix an error bug in final form (touched does not change after form reset)#
    setFormKey(formKey + 1);
    // options.push(input.name, data);
    // }
    // })
    // .then(() => shouldCollapseForm && toggleHide(true))
    // .catch(setError)
    // .finally(() => setLoading(false));
    // };
  }
  // make sure to handle hidden and all conditional cases if needed

  const getCols = (schema, deleteCB, editCB) => {
    const disableButton = Object.values(schema).some(({ disableDeleteButton }) => disableDeleteButton);
    const schemaName = Object.values(schema).length && Object.values(schema)[0]?.schema;
    let columns = Object.entries(schema)
      .map(([key, value]) => ({ dataIndex: key, title: value.label }));
    if (schemaName === 'candidacyLimitForm') {
      columns = columns.concat([
        {
          title: 'العدد الكلي',
          render: (rowData, index) => (
            <>{rowData?.total}</>
          )
          ,
        },
        {
          title: 'خيارات',
          render: (rowData, index) => (
            <button
              type="button"
              disabled={disableButton}
              className={styles['del-button']}
              onClick={() => deleteCB(rowData, rowData.__index__)}
            >
              <i className={`fa fa-trash ${disableButton ? styles['disable-del-button'] : ''}`} />
            </button>
          ),
        },
      ]);
    } else {
      columns = columns.concat({
        title: 'خيارات',
        render: (rowData, index) => (
          <div className={styles['action-btns']}>
            {allowDelete && (
              <button
                type="button"
                disabled={disableButton}
                className={styles['del-button']}
                onClick={() => deleteCB(rowData, rowData.__index__)}
              >
                <i className={`fa fa-trash ${disableButton ? styles['disable-del-button'] : ''}`} />
              </button>
            )}
            {allowEdit
            && (
              <EditAction
                onEdit={(data) => editCB(data, rowData.__index__)}
                initialValues={tableData[rowData.__index__]}
                schema={schema}
              />
            )}
          </div>
        ),
      });
    }
    return columns;
  };

  const tableCols = useMemo(
    () => (tableData.length
      ? getCols(schema, (_, index) => {
        if (tableData.length > 1) {
          formRef.change(
            input.name,
            tableData.filter((item, i) => index !== i),
          );
        } else {
          formRef.change(input.name, undefined);
        }
        setTableData(tableData.filter((_, i) => i !== index));
      }, (data, index) => {
        const newDataValues = tableData.map((eachRow, eachIndex) => (eachIndex === index ? data : eachRow));
        formRef.change(input.name, newDataValues);
        setTableData(newDataValues);
      })
      : []),
    [formRef, input.name, schema, tableData],
  );

  const newTableData = useMemo(() => {
    const indexedData = tableDataDisplay.map((row, i) => ({
      ...row,
      __index__: i,
    }));
    if (renderAsGroup) {
      return Object.values(
        _groupBy(indexedData, renderAsGroup),
      ).flatMap((group, groupIndex) => group.map((row, i) => (i === 0
        ? { ...row, __odd__: groupIndex % 2 }
        : { ...row, [renderAsGroup]: '', __odd__: groupIndex % 2 })));
    }
    return indexedData;
  }, [tableDataDisplay, renderAsGroup]);

  const extraTableProps = useMemo(() => {
    if (renderAsGroup) {
      return {
        generatePropsPerRow: row => (row.__odd__
          ? { className: styles.oddRow }
          : { className: styles.evenRow }),
      };
    }
  }, [renderAsGroup]);

  const extraFormProps = useMemo(() => {
    // if (!uniqueCol.length) {
    const rows = renderAsGroup
      ? _groupBy(tableData, renderAsGroup)
      : tableData;
    if (renderAsGroup) {
      return validateUniqueness(rows, uniqueCol)(renderAsGroup);
    }
    return validateUniqueness(rows, uniqueCol)();
    // }
  }, [renderAsGroup, tableData, uniqueCol]);

  const extraFormProps2 = useMemo(() => {
    if (alikeCol.length) {
      const rows = renderAsGroup
        ? _groupBy(tableData, renderAsGroup)
        : tableData;
      if (renderAsGroup) {
        return validateAlike(rows, alikeCol)(renderAsGroup);
      }
      return validateAlike(rows, alikeCol)();
    }
    return () => ({});
  }, [alikeCol, renderAsGroup, tableData]);

  return (
    <section className={styles['multiple-fields']}>
      {/* <div className={`${styles['margin-top-bottom-2rem']} ${loading ? '' : 'display-none'}`}><LoadingSpinner spinnerText="جاري مراجعة البيانات" /></div> */}
      {/* <div className={`${styles['margin-top-bottom-2rem']} ${styles['text-center']} ${isFormHidden ? '' : 'display-none'}`}><button type="button" className="primary-btn-2" onClick={() => toggleHide(false)}>{i18next.t('addanotherparty')}</button></div> */}

      <div className={`${styles['fields-row']} $`}>
        <h4>{label}</h4>

        <div className={styles['fields-row-content']}>
          {maxEntries > 0
            ? (
              <Form
                validate={formValues => ({
                  ...extraFormProps(formValues),
                  ...extraFormProps2(formValues),
                })}
                // eslint-disable-next-line react/jsx-no-bind
                onSubmit={submitHandler}
                key={formKey}
                render={props => (
                  <FormComp
                    {...props}
                    parentSchema={rawSchema}
                    hasSubmitErrors={Boolean(error)}
                    // parentErrors={hasError && formatErrorText(hasError, label)}
                    typeGetter={typeGetter}
                    rawSchema={schema}
                    formValues={formValues}
                    formProps={formProps}
                    inputProps={input}
                    parentFormRef={formRef}
                    parentName={input.name}
                  />
                )}
              />
            ) : undefined}
        </div>
      </div>

      {newTableData.length > 0 && (
        <div className={styles['entries-table']}>
          <Table
            dataSource={newTableData}
            columns={tableCols}
            valuesMap={valuesMap}
            {...extraTableProps}
          />
        </div>
      )}
      {hasError ? (
        <FeedbackMessage
          type="error"
          message={formatErrorText(hasError, label, input)}
        />
      ) : null}
    </section>
  );
};
