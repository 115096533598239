/* eslint-disable camelcase */
/* eslint-disable radix */
import _ from 'lodash/fp';
import get from 'lodash/fp/get';
import moment from 'moment';
import { getLookupOptions } from './helpers';

export const validationFn = async (values) => {
  const {
    equation,
    subjects,
    subjectsWithoutFullMark,
    optionalSubjects,
    grades,
    Group_secondarySubjects,
    Group_seniorSubjects,
    testDate,
    coursePeriod,
    abilitiesPercentageFactor,
    degreePercentageFactor,
    subjectsOL,
    subjectsAS,
    subjectsAL,
    subjectsWithSpecializationAndClass,
    sat2SubjectObligatory,
    sat2SubjectOptional,
    levels,
    advancedSubjectsObligatory,
    advancedSubjectsOptional,
    specialization,
    minimumNumberOfSubjects,
    optionalMax,
    percentages,
    levelsFactors,
    minimumNumberOfOptionalSubjects,
    maxNumberOfOptionalSubjects,
    motherLanguageSubjects,
    mainSubjects,
  } = values;

  if (
    ['3'].includes(equation)
    && subjects
    && optionalSubjects
    && !_(
      _.intersection(
        subjects.map(({ subjectName }) => subjectName),
        optionalSubjects.map(({ subjectName }) => subjectName),
      ),
    ).isEmpty()
  ) {
    return {
      optionalSubjects: 'uniqueOptionalSubjects',
    };
  }

  if (
    ['3'].includes(equation)
    && optionalSubjects
    && optionalMax
    && optionalSubjects.length < Number(optionalMax)
  ) {
    return {
      optionalSubjects: 'invalidOptionalMax',
    };
  }

  if (
    ['8'].includes(equation)
    && Group_seniorSubjects
    && Group_seniorSubjects.optionalSubjects
    && Group_seniorSubjects
    && Group_seniorSubjects.subjects
    && !_(
      _.intersection(
        Group_seniorSubjects
          && Group_seniorSubjects.subjects.map(({ subjectName }) => subjectName),
        Group_seniorSubjects
          && Group_seniorSubjects.optionalSubjects.map(
            ({ subjectName }) => subjectName,
          ),
      ),
    ).isEmpty()
  ) {
    return {
      Group_seniorSubjects: {
        optionalSubjects: 'uniqueOptionalSubjects',
      },
    };
  }

  if (
    ['8'].includes(equation)
    && Group_secondarySubjects
    && Group_secondarySubjects.subjects
    && Group_secondarySubjects
    && Group_secondarySubjects.optionalSubjects
    && !_(
      _.intersection(
        Group_secondarySubjects
          && Group_secondarySubjects.subjects.map(
            ({ subjectName }) => subjectName,
          ),
        Group_secondarySubjects
          && Group_secondarySubjects.optionalSubjects.map(
            ({ subjectName }) => subjectName,
          ),
      ),
    ).isEmpty()
  ) {
    return {
      Group_secondarySubjects: {
        optionalSubjects: 'uniqueOptionalSubjects',
      },
    };
  }

  if (
    ['8'].includes(equation)
    && Group_seniorSubjects
    && Group_seniorSubjects.optionalMax
    && Group_seniorSubjects
    && Group_seniorSubjects.optionalSubjects
    && Group_seniorSubjects.optionalSubjects.length
      < Number(Group_seniorSubjects.optionalMax)
  ) {
    return {
      Group_secondarySubjects: {
        optionalSubjects: 'invalidOptionalMax',
      },
    };
  }

  if (
    ['8'].includes(equation)
    && Group_secondarySubjects
    && Group_secondarySubjects.optionalMax
    && Group_secondarySubjects
    && Group_secondarySubjects.optionalSubjects
    && Group_secondarySubjects.optionalSubjects.length
      < Number(Group_secondarySubjects.optionalMax)
  ) {
    return {
      Group_secondarySubjects: {
        optionalSubjects: 'invalidOptionalMax',
      },
    };
  }

  if (
    equation === '4'
    && grades
    && grades.some(({ from, to }) => parseInt(to) < parseInt(from))
  ) {
    return {
      grades: 'invalidGrades',
    };
  }

  if (equation === '13') {
    if (
      degreePercentageFactor
      && degreePercentageFactor.some(
        ({ from, to }) => parseInt(to) <= parseInt(from),
      )
    ) {
      return {
        degreePercentageFactor: 'invalidGrades',
      };
    }
    if (
      abilitiesPercentageFactor
      && abilitiesPercentageFactor.some(
        ({ from, to }) => parseInt(to) <= parseInt(from),
      )
    ) {
      return {
        abilitiesPercentageFactor: 'invalidGrades',
      };
    }
  }

  // if (equation === '15' && subjects && optionalSubjects) {
  //   if (subjects.length < 1 || optionalSubjects.length < 1) {
  //     return {
  //       subjects: subjects.length === 5 ? undefined : 'invalidSubjects5',
  //       optionalSubjects:
  //         optionalSubjects.length === 3 ? undefined : 'invalidSubjects3',
  //     };
  //   }
  // }

  // if (equation === '19' && subjects) {
  //   if (subjects.length < 7) {
  //     return {
  //       subjects: 'invalidSubjects7',
  //     };
  //   }
  // }

  if (['23'].includes(equation) && subjects) {
    if (subjects.length < 9) {
      return {
        subjects: 'invalidSubjects9',
      };
    }
  }

  if (['24', '25'].includes(equation) && subjects) {
    if (subjects.length < 8) {
      return {
        subjects: 'invalidSubjects8',
      };
    }
  }

  // if (['13'].includes(equation) && subjectsWithoutFullMark) {
  //   if (subjectsWithoutFullMark.length < 8) {
  //     return {
  //       subjectsWithoutFullMark: 'invalidSubjects8',
  //     };
  //   }
  // }

  if (['16'].includes(equation) && subjectsWithoutFullMark) {
    if (subjectsWithoutFullMark.length < 6) {
      return {
        subjectsWithoutFullMark: 'invalidSubjects6',
      };
    }
  }

  if (coursePeriod && testDate) {
    const periods = await getLookupOptions({ name: 'coursePeriods' });
    const currentPeriod = periods.find(p => p.value === coursePeriod);
    const coursePeriodStartDate = get(currentPeriod, 'metaData.data.startDate');
    if (
      coursePeriodStartDate
      && moment(testDate).isSameOrAfter(moment(coursePeriodStartDate))
    ) {
      return {
        testDate: 'invalidtestDate',
      };
    }
  }

  if (['22'].includes(equation) && subjectsOL && subjectsOL.length < 8) {
    return {
      subjectsOL: 'invalidSubjectsOL',
    };
  }

  if (
    ['22'].includes(equation)
    && subjectsAS
    && subjectsAS.length
    && (!subjectsAL || !subjectsAL.length)
  ) {
    return {
      subjectsAL: 'required',
    };
  }

  if (
    ['22'].includes(equation)
    && subjectsAL
    && subjectsAL.length
    && (!subjectsAS || !subjectsAS.length)
  ) {
    return {
      subjectsAS: 'required',
    };
  }

  if (['22'].includes(equation) && subjectsAS && subjectsAS.length < 2) {
    return {
      subjectsAS: 'invalidSubjects2',
    };
  }

  if (['22'].includes(equation) && subjectsAL && subjectsAL.length < 1) {
    return {
      subjectsAL: 'invalidSubjects1',
    };
  }

  // eslint-disable-next-line eqeqeq
  if (
    ['21', '28', '30'].includes(equation)
    && subjectsWithSpecializationAndClass
    && subjectsWithSpecializationAndClass.filter(
      // eslint-disable-next-line eqeqeq
      ({ classGrade }) => classGrade == 12,
    ).length < 4
  ) {
    return {
      subjectsWithSpecializationAndClass: 'invalidSubjectsLevel12',
    };
  }

  if (
    ['21', '28'].includes(equation)
    && !['scientific_science', 'sport_science'].includes(specialization)
  ) {
    if (sat2SubjectObligatory && sat2SubjectObligatory.length) {
      return {
        sat2SubjectObligatory: 'invalidSat2SubjectsExistence',
      };
    }

    if (sat2SubjectOptional && sat2SubjectOptional.length) {
      return {
        sat2SubjectOptional: 'invalidSat2SubjectsExistence',
      };
    }
  }

  if (
    ['21', '28'].includes(equation)
    && sat2SubjectObligatory
    && sat2SubjectObligatory.length > 1
  ) {
    return {
      sat2SubjectObligatory: 'invalidSat2SubjectObligatory',
    };
  }

  if (
    ['21', '28'].includes(equation)
    && sat2SubjectObligatory
    && sat2SubjectOptional
    && !_(sat2SubjectObligatory).differenceBy(sat2SubjectOptional, 'subjectName').size()
  ) {
    return {
      sat2SubjectOptional: 'invalidOptionalWithObligatory',
    };
  }

  if (
    ['21', '28', '30'].includes(equation)
    && subjectsWithSpecializationAndClass
    && _(subjectsWithSpecializationAndClass)
      .groupBy('classGrade')
      .values()
      .some(
        subjectsList => _(subjectsList).uniqBy('subjectName').size() !== subjectsList.length,
      )
  ) {
    return {
      subjectsWithSpecializationAndClass: 'invalidSubjectsUniqueLevels',
    };
  }

  if (
    ['5'].includes(equation)
    && levelsFactors
    && levels
    && _(levels)
      .groupBy('levelNumber')
      .keys()
      .some(
        index => !levelsFactors.map(({ levelNumber }) => levelNumber).includes(index),
      )
  ) {
    return {
      levelsFactors: 'invalidLevelsFactors',
    };
  }

  if (
    ['5'].includes(equation)
    && levels
    && _(levels)
      .groupBy('levelNumber')
      .values()
      .some(
        subjectsList => _(subjectsList).uniqBy('subjectName').size() !== subjectsList.length,
      )
  ) {
    return {
      levels: 'invalidSubjectsUniqueLevels',
    };
  }

  if (['20'].includes(equation) && !['scientific_science', 'sport_science'].includes(specialization) && advancedSubjectsObligatory && advancedSubjectsObligatory.length) {
    return {
      advancedSubjectsObligatory: 'invalidAdvancedSubjectsObligatory',
    };
  }

  if (['20'].includes(equation) && ['scientific_science', 'sport_science'].includes(specialization) && !advancedSubjectsObligatory) {
    return {
      advancedSubjectsObligatory: 'invalidAdvancedSubjectsObligatoryExistence',
    };
  }

  if (['20'].includes(equation) && !['scientific_science', 'sport_science'].includes(specialization) && advancedSubjectsOptional && advancedSubjectsOptional.length < 3) {
    return {
      advancedSubjectsOptional: 'invalidAdvancedSubjectsOptional',
    };
  }

  if (['1', '2', '26'].includes(equation) && subjects && subjects.length < Number(minimumNumberOfSubjects)) {
    return {
      subjects: 'invalidSubjectsLengthWitMinimal',
    };
  }

  if (['7', '13', '11', '17', '27'].includes(equation) && subjectsWithoutFullMark && subjectsWithoutFullMark.length < Number(minimumNumberOfSubjects)) {
    return {
      subjectsWithoutFullMark: 'invalidSubjectsLengthWitMinimal',
    };
  }

  if (['15', '19', '29'].includes(equation) && optionalSubjects && optionalSubjects.length < Number(minimumNumberOfOptionalSubjects)) {
    return {
      optionalSubjects: 'invalidSubjectsLengthWitMinimal',
    };
  }

  if (['19', '29'].includes(equation) && optionalSubjects && optionalSubjects.length < Number(maxNumberOfOptionalSubjects)) {
    return {
      optionalSubjects: 'invalidSubjectsLengthWitMax',
    };
  }

  if (
    ['19', '29'].includes(equation)
    && mainSubjects
    && motherLanguageSubjects
    && !_(
      _.intersection(
        mainSubjects.map(({ subjectName }) => subjectName),
        motherLanguageSubjects.map(({ subjectName }) => subjectName),
      ),
    ).isEmpty()
  ) {
    return {
      motherLanguageSubjects: 'uniqueMotherLanguage',
    };
  }

  if (
    ['19', '29'].includes(equation)
    && optionalSubjects
    && motherLanguageSubjects
    && !_(
      _.intersection(
        optionalSubjects.map(({ subjectName }) => subjectName),
        motherLanguageSubjects.map(({ subjectName }) => subjectName),
      ),
    ).isEmpty()
  ) {
    return {
      motherLanguageSubjects: 'uniqueMotherLanguage',
    };
  }

  if (
    equation === '16'
    && percentages
    && percentages.some(({ from, to }) => parseInt(to) < parseInt(from))
  ) {
    return {
      percentages: 'invalidGrades',
    };
  }
};
