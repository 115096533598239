import React from 'react';

import DynamicSettings from './Template/Template';
import DynamicSettings2 from './Template2/Template2';

export const CertificateForm = props => (
  <DynamicSettings
    {...props}
    title="اعدادات الشهادة"
    form="certificateForm"
    api="certificatesSettings"
    redirectUrl="certificates"
    editText="certificateEdit"
    addText="certificateAdd"
    labelList={['degreeName', 'country', 'year']}
  />
);

export const CoursePeriodsForm = props => (
  <DynamicSettings
    {...props}
    title="اعدادات الفترات التدريبية"
    form="coursePeriodsForm"
    api="coursePeriodsSettings"
    redirectUrl="course-periods-list"
    editText="periodsEdit"
    addText="periodsAdd"
  />
);

export const CoordinationForm = props => (
  <DynamicSettings
    {...props}
    title="اعدادات التنسيق"
    form="coordinationForm"
    api="coordinations"
    redirectUrl="coordination-list"
    editText="coordinationEdit"
    addText="coordinationAdd"
    labelList={['title', 'type', 'year']}
    nextStepUrl="coordination-faculties"
    nextStepText="addCoordinationAndFaculties"
    nextStepTitleKey="title"
  />
);
export const EquationForm = props => (
  <DynamicSettings
    {...props}
    title="اعدادات المعادله"
    form="equationForm"
    api="equationSettings"
    redirectUrl="equations-list"
    editText="equationEdit"
    addText="equationAdd"
  />
);

export const TestAppointmentsForm = props => (
  <DynamicSettings
    {...props}
    title="مواعيد إختبارات تحديد المستوى"
    form="testAppointmentsForm"
    api="testAppointmentsSettings"
    redirectUrl="test-appointments-list"
    editText="appointmentsEdit"
    addText="appointmentsAdd"
  />
);

export const FeesForm = props => (
  <DynamicSettings
    {...props}
    title="رسوم الخدمات"
    form="feesForm"
    api="fees"
    redirectUrl="fees-list"
    editText="feesEdit"
    addText="feesAdd"
  />
);
export const EffectiveInformationFrom = props => (
  <DynamicSettings
    {...props}
    title="المعلومات الإثرائيه"
    form="effectiveInformationForm"
    api="effectiveInformation"
    redirectUrl="effective-information-list"
    editText="effectiveInformationEdit"
    addText="effectiveInformationAdd"
  />
);
export const PaymentEffectiveInformationFrom = props => (
  <DynamicSettings
    {...props}
    title="المعلومات الإثرائيه للدفع"
    form="paymentEffectiveInformationForm"
    api="paymentEffectiveInformation"
    redirectUrl="Payment-effective-information-list"
    editText="paymentEffectiveInformationEdit"
    addText="effectiveInformationAdd"
  />
);
export const CandidacyLimitForm = props => (
  <DynamicSettings
    {...props}
    title="الحد الأقصى للترشح"
    form="candidacyLimitForm"
    api="condidancyLimit"
    redirectUrl="candidacy-limit-list"
    editText="candidacyLimitEdit"
    addText="candidacyLimitAdd"
  />
);
export const PostGraduateForm = props => (
  <DynamicSettings
    {...props}
    title="الدرجات العليا"
    form="postGraduateForm"
    api="postGraduateSettings"
    redirectUrl="post-graduate-list"
    editText="postGraduateEdit"
    addText="postGraduateAdd"
    nextStepUrl="post-graduate-faculties"
    nextStepText="addPosGraduateAndFaculties"
    nextStepTitleKey="degree"
    nextStepTitleFormatter={(schema, title) => schema?.degree?.uniforms?.options.find(({ value }) => value === title)?.label}
  />
);

export const ArabicDepProgramsForm = props => (
  <DynamicSettings
    {...props}
    title="برامج اللغة العربية"
    form="arabicDepProgramsForm"
    api="arabicProgramsSettings"
    redirectUrl="arabic-programs"
    editText="arabicDepProgramsEdit"
    addText="arabicDepProgramsAdd"
  />
);
export const ArabicSkillsForm = props => (
  <DynamicSettings
    {...props}
    title="مهارات اللغة العربية"
    form="arabicSkillsForm"
    api="arabicSkills"
    redirectUrl="arabic-skills-list"
    editText="arabicSkillsEdit"
    addText="arabicSkillsAdd"
  />
);

export const ArabicLevelsForm = props => (
  <DynamicSettings
    {...props}
    title="مستويات اللغة العربية"
    form="arabicLevelsForm"
    api="arabicLevelsSettings"
    redirectUrl="arabic-levels-list"
    editText="arabicLevelsEdit"
    addText="arabicLevelsAdd"
  />
);
export const TrainingForm = props => (
  <DynamicSettings
    {...props}
    title="التدريب/الدراسة"
    form="trainingForm"
    api="trainingsSettings"
    redirectUrl="training-list"
    editText="trainingEdit"
    addText="trainingAdd"
  />
);

export const CoordinationFaculties = props => (
  <DynamicSettings2
    {...props}
    relation="كليات"
    title="الكلية"
    parentTitleAttribute="title"
    titleAttribute="faculty"
    form="CoordinationFaculties"
    api="lists"
    slug="coordinationsFaculties"
    path="coordinations"
    groupBy="specialization"
    addText="إضافة كلية"
    formatValuesFn={data => ({ ...data, percentage: Number(data.percentage) })}
    fallbackUrl="coordination-list"
  />
);

export const PostGraduateFaculties = props => (
  <DynamicSettings2
    {...props}
    relation="بيانات الكليات والتخصصات وفقا لنوع الدراسة للدرجة العلمية "
    title="بيانات الكلية"
    parentTitleAttribute="degree"
    titleAttribute="faculty"
    form="postGraduateFaculties"
    api="lists"
    slug="postGraduateFaculties"
    path="postGraduateSettings"
    groupBy="studyType"
    addText="إضافة بيانات الكلية"
    fallbackUrl="post-graduate-list"
  />
);
