import {
  createStore, combineReducers, compose, applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import lookUpsReducer from '../reducers/lookUpsData.reducer';
import userReducer from '../reducers/userData.reducer';

const rootReducer = combineReducers({
  lookUpsReducer,
  userReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer, composeEnhancers(applyMiddleware(thunk)),
);

export default store;
