import React from 'react';
import CommonTabs from '../../Components/tabs/index';
import Styles from './GeneralLookups.module.scss';

const GeneralLookups = ({ history }) => {
  const tabs = [
    {
      label: 'الدول',
      key: 'countries',
    },
    {
      label: 'نوع الدراسة',
      key: 'studyTypes',
    },
    {
      label: 'التخصصات',
      key: 'specializations',
    },
    {
      label: 'المؤهلات',
      key: 'qualification',
    },
    {
      label: 'الجامعات',
      key: 'universities',
    },
    {
      label: 'الكليات - الجامعي',
      key: 'faculties',
    },
    {
      label: 'الكليات - الدراسات العليا',
      key: 'pgNewFaculties',
    },
    {
      label: 'ملاحظات العضو الفني',
      key: 'notes',
    },
    {
      label: 'البرامج',
      key: 'programs',
    },
    {
      label: 'الخدمات',
      key: 'services',
    },
    {
      label: 'رسوم الخدمات',
      key: 'feesTypes',
    },
    {
      label: 'أنواع الاقامة',
      key: 'accomodationTypes',
    },
  ];
  return (
    <div className={Styles['lookups-container']}>
      <CommonTabs tabs={tabs} destroyInactiveTabPane history={history} />
    </div>
  );
};

export default GeneralLookups;
