import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import Spin from 'antd/lib/spin';
import Modal from 'antd/lib/modal';
import _ from 'lodash';
import Select from 'antd/lib/select';
import moment from 'moment';
import CustomButton from '../../../Components/UIElements/Button';
import FormFields from '../../../Components/FormFields/FormFields';

import SETTINGS_APIS from '../../../APIs/settings_apis';

import i18next from '../../../i18next/index';

import {
  getLookupOptions,
} from '../../../FormSchemas/form-dynamic/helpers';

import {
  NationalitiesNumberValidation, serverErrorUI, showNotification, studyStagesValidation,
} from '../../../util/helpers';

import { validationFn } from '../../../FormSchemas/form-dynamic/validator';

import styles from './Template.module.scss';

const { Option } = Select;

const DynamicSettings = ({
  history: { push },
  location: { state },
  title,
  form,
  api,
  redirectUrl,
  editText,
  addText,
  labelList,
  nextStepUrl,
  nextStepText,
  nextStepTitleKey,
  nextStepTitleFormatter,
}) => {
  const [schema, setSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setServerError] = useState(null);

  const [initialValues, setInitialValues] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [options, setOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const [certificates, setCertificates] = useState([]);
  const [countries, setCountries] = useState([]);

  const toNextPage = useRef(null);

  useEffect(() => {
    const getSchema = async () => {
      setLoading(true);
      const module = await import(`../../../FormSchemas/form-dynamic/${form}`);
      setSchema(await module.schema());
      setLoading(false);
    };
    getSchema();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getLookupsOptionForLabelList = async () => {
    setCertificates(await getLookupOptions({
      name: 'certificates',
    }));

    setCountries(
      await getLookupOptions({ name: 'countries' }),
    );
  };

  useEffect(() => {
    const getInitialValues = async () => {
      if (labelList && !isEdit) await getLookupsOptionForLabelList();

      const {
        data: { options: optionsData },
      } = await SETTINGS_APIS(api).getAll();

      if (!state) {
        setOptions(optionsData);
        return;
      }

      const {
        record: { value },
      } = state;

      setLoadingData(true);
      const { data } = optionsData.find(
        option => option.value === value,
      ).metaData;
      setInitialValues(data);
      setIsEdit(true);
      setLoadingData(false);
    };
    getInitialValues();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (form === 'candidacyLimitForm' && isEdit && !loading) {
      changeCandidacySchema();
    }
  }, [isEdit, loading]);

  async function changeCandidacySchema() {
    const studyStagevalidation = await studyStagesValidation(initialValues, setSubmitting, setLoading);
    if (!loading && studyStagevalidation) {
      const newSchema = JSON.parse(JSON.stringify(schema));
      const maxNationalityNumberSchema = newSchema?.maxNationalityNumber?.schema;
      Object.values(maxNationalityNumberSchema).forEach((item) => {
        item.disableDeleteButton = true;
      });
      setSchema(newSchema);
    }
  }
  const handleFormSubmission = async (data) => {
    setSubmitting(true);
    setLoading(true);
    setServerError(null);
    function NationalitiesNumberValidationMessage() {
      if (form === 'candidacyLimitForm') {
        const nationalityValidation = NationalitiesNumberValidation(data, setSubmitting, setLoading);
        if (nationalityValidation) {
          showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: 'مجموع الأعداد في الجنسيات لا يجب ان يكون اكبر من الحد الأقصى للترشح',
          });
          return true;
        }
      }
    }
    try {
      if (form === 'candidacyLimitForm') {
        data.maxNationalityNumber = data?.maxNationalityNumber.map((nationality) => {
          if ('total' in nationality) {
            return nationality;
          }
          return { ...nationality, total: nationality?.amount };
        });
        const unifiedData = data?.maxNationalityNumber.reduce((acc, curr) => {
          if (curr?.nationality in acc) {
            acc[curr?.nationality].push(curr);
          } else {
            acc[curr?.nationality] = [curr];
          }
          return acc;
        }, {});
        const maxNationalityNumberUpdated = [];
        let newCurrent = { amount: 0, total: 0 };
        Object.values(unifiedData).forEach((item) => {
          item.forEach((n) => {
            newCurrent.amount = Number(newCurrent.amount) + Number(n.amount);
            newCurrent.total = Number(newCurrent.total) + Number(n.total);
            newCurrent.nationality = n.nationality;
          });

          maxNationalityNumberUpdated.push(newCurrent);
          newCurrent = { amount: 0, total: 0 };
        });
        data.maxNationalityNumber = maxNationalityNumberUpdated;
      }
      let result;

      if (isEdit) {
        if (form === 'candidacyLimitForm') {
          const studyStagevalidation = await studyStagesValidation(initialValues, setSubmitting, setLoading);
          if (studyStagevalidation) {
            const newValues = JSON.stringify({ faculty: data.faculty, studyStages: data.studyStages, university: data.university });
            const oldValues = JSON.stringify({
              faculty: initialValues.faculty,
              studyStages: initialValues.studyStages,
              university: initialValues.university,
            });
            const newCandidacyLimit = Number(data.condidacyLimit);
            const oldCandidacyLimit = Number(initialValues.condidacyLimit);
            if (newCandidacyLimit < oldCandidacyLimit) {
              setSubmitting(false);
              setLoading(false);
              return showNotification({
                type: 'error',
                message: 'حدث خطأ أثناء التنفيذ',
                description: 'لا يمكن إنقاص الحد الأقصى للترشح أثناء المرحلة الدراسية',
              });
            }
            if (newValues !== oldValues) {
              setSubmitting(false);
              setLoading(false);
              return showNotification({
                type: 'error',
                message: 'حدث خطأ أثناء التنفيذ',
                description: 'لا يمكن تعديل المرحله الدراسيه او الكليه او الجامعه أثناء المرحلة الدراسية ',
              });
            }
          }
          data.remainingcondidacyLimit = Number(data.remainingcondidacyLimit) + (Number(data.condidacyLimit) - Number(initialValues.condidacyLimit));
          if (NationalitiesNumberValidationMessage()) {
            setSubmitting(false);
            setLoading(false);
            return;
          }
        }
        result = await SETTINGS_APIS(api).edit({
          metaData: { data },
          value: state.record.value,
        });
      } else {
        if (NationalitiesNumberValidationMessage()) {
          setSubmitting(false);
          setLoading(false);
          return;
        }
        result = await SETTINGS_APIS(api).add({
          metaData: { data },
          restoreValue: selectedValue,
        });
      }

      if (result.data.success) {
        setSubmitting(false);
        setLoading(false);
        showNotification({
          type: 'success',
          message: 'تمت العملية بنجاح',
        });
        if (toNextPage?.current) {
          const response = result?.data?.item;
          let stepTitle = response?.metaData?.data?.[nextStepTitleKey];
          if (nextStepTitleFormatter) {
            stepTitle = nextStepTitleFormatter(schema, stepTitle);
          }
          push({
            pathname: `/${nextStepUrl}`,
            state: { ...({ value: response?.value, [nextStepTitleKey]: stepTitle || '' }) },
          });
        } else {
          push(`/${redirectUrl}`);
        }
      }
    } catch (e) {
      const { errors, message } = e?.response?.data;

      setServerError(errors);
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: message,
      });

      setSubmitting(false);
      setLoading(false);
    }
  };

  const mapValues = (data, { degreeName, type, country }) => {
    const coordinationTypes = {
      1: 'شهادة مصرية',
      2: 'شهادة من خارج مصر و أم مصرية',
      3: 'شهادة من خارج مصر و أم غير مصرية',
    };

    let finalData = data;

    if (degreeName) {
      finalData = finalData.set('degreeName', _.get(certificates.find(({ value: certVal }) => certVal === degreeName), 'label'));
    }

    if (country) {
      finalData = finalData.set('country', _.get(countries.find(({ value: countryVal }) => countryVal === country), 'label'));
    }

    if (type) {
      finalData = finalData.set('type', coordinationTypes[type]);
    }

    return finalData;
  };

  return (
    <>
      <div className={styles['services-container']}>
        <div className={styles['form-container']}>
          <h2 className={styles.title}>{title}</h2>
          {loading || loadingData ? (
            <div className={styles.spinner}>
              <Spin
                size="large"
                tip={submitting ? 'برجاء الانتظار ...' : 'تحميل ...'}
              />
            </div>
          ) : (
            <Form
              onSubmit={handleFormSubmission}
              initialValues={initialValues}
              validate={validationFn}
              render={({
                handleSubmit,
                dirtySinceLastSubmit,
                submitting: formSubmitting,
                hasValidationErrors,
                values,
                form: { change },
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className={(styles['input-group'], styles['dynamic-form'])}
                >
                  <FormFields
                    data={{ fieldsSchema: schema }}
                    formValues={values}
                    changeFieldValue={change}
                    originalStepValues={initialValues}
                  />
                  {serverError && !dirtySinceLastSubmit && !formSubmitting ? (
                    <div className={styles['form-error']}>
                      <ul>{serverErrorUI(serverError)}</ul>
                    </div>
                  ) : null}

                  <div className={styles['buttons-container']}>
                    {isEdit || !labelList ? null : (
                      <CustomButton type="button" primary action={() => setIsVisible(true)}>
                        <div>
                          استعادة اعدادات سابقة
                        </div>
                      </CustomButton>
                    )}

                    {(!nextStepText || isEdit) && (
                      <CustomButton type="submit" secondary>
                        <div>
                          {isEdit ? i18next.t(editText) : i18next.t(addText)}
                        </div>
                      </CustomButton>
                    )}

                    {!isEdit && nextStepText && (
                      <CustomButton
                        type="button"
                        secondary
                        action={() => {
                          toNextPage.current = true;
                          handleSubmit();
                        }}
                      >
                        <div>
                          { i18next.t(nextStepText) }
                        </div>
                      </CustomButton>
                    )}
                  </div>
                </form>
              )}
            />
          )}
        </div>
      </div>

      <Modal
        title={`استعادة ${title}`}
        visible={isVisible}
        centered
        onCancel={() => setIsVisible(false)}
        footer={null}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles['form-body']}>
          <Select
            showSearch
            placeholder={`اختر ${title}`}
            allowClear
            onSelect={setSelectedValue}
            size="large"
            className={styles['settings-select-input']}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {options.map((option) => {
              const { degreeName, type, country } = option.metaData.data;
              const { value, metaData: { data } } = option;
              const dataList = _(data).pick(labelList);
              return (
                <Option value={value}>
                  { mapValues(dataList, { degreeName, type, country })
                    .values()
                    .join(' - ')}
                </Option>
              );
            })}
          </Select>

          <div className={styles['btns-container']}>

            <CustomButton
              type="button"
              action={
                () => {
                  setInitialValues(
                    options.find(option => option.value === selectedValue).metaData.data,
                  );
                  setIsVisible(false);
                }
              }
            >
              استعادة
            </CustomButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DynamicSettings;
