import React, {
  useState, useMemo, useEffect, useReducer,
} from 'react';

import styles from './Table.module.scss';

const getRowData = ({
  dataIndex, title, render,
}, valuesMap, dataSource, index, parentIndex, toggleExbandableRow, shouldOpenExbandableRow, setExpandedRowData, transformArrays) => {
  const useRenderFunc = Boolean(render);
  const key = useRenderFunc ? String(index) : dataIndex;
  let content; let
    mapF;
  if (useRenderFunc) {
    content = render(dataSource, parentIndex, toggleExbandableRow, shouldOpenExbandableRow, setExpandedRowData);
  } else if ((mapF = valuesMap[dataSource[dataIndex]])) {
    content = mapF(dataSource, parentIndex);
  } else {
    content = dataSource[dataIndex];
    if (Array.isArray(content)) content = transformArrays(content, dataSource, parentIndex);
  }
  return {
    key,
    content,
    title,
  };
};

const VerComp = ({
  columns, valuesMap, transformArrays, dataSource, parentIndex,
}) => (
  <tbody key={String(parentIndex)}>
    {columns.map((column, index) => {
      const { key, content, title } = getRowData(column, valuesMap, dataSource, index, parentIndex, undefined, undefined, undefined, transformArrays);
      return (
        <tr key={key}>
          <td>{title}</td>
          <td>{content}</td>
        </tr>
      );
    })}
  </tbody>
);

const HorComp = ({
  columns, valuesMap, transformArrays,
  expandedRowRender, generatePropsPerRow, dataSource, parentIndex,
}) => {
  const [shouldOpenExbandableRow, toggleExbandableRow] = useState(false);
  const [expandedRowData, setExpandedRowData] = useState('');
  return (
    <>
      <tr key={String(parentIndex)} {...(generatePropsPerRow && generatePropsPerRow(dataSource))}>
        {columns.map((column, index) => {
          const { key, content } = getRowData(column, valuesMap, dataSource, index, parentIndex, toggleExbandableRow, shouldOpenExbandableRow, setExpandedRowData, transformArrays);
          return <td key={key}>{content}</td>;
        })}
      </tr>
      {(shouldOpenExbandableRow) && <tr key={`${parentIndex}-expanded`}><td className={styles['expand-row-primary-color']} colSpan={String(columns.length)}>{expandedRowData}</td></tr>}
    </>
  );
};

const Wrapper = ({ children, wrapper }) => wrapper(children);

export default function Table({
  perPage = Infinity,
  dataSource, columns, vertical: isVertical, valuesMap = {}, expandedRowRender, generatePropsPerRow, transformArrays = x => x, ...props
}) {
  const [{ offSet, start, currentPageNumber }, setPaginationConfig] = useReducer((base, point) => ({ ...base, ...point }), { offSet: perPage, start: 0, currentPageNumber: 0 });
  const pageDataSource = useMemo(() => dataSource.slice(start, offSet), [dataSource, start, offSet]);
  const pagesNumbers = Array.from({ length: Math.ceil(dataSource.length / perPage) });
  const GenerateRow = isVertical ? VerComp : HorComp;
  return (
    <>
      <table {...props} className={isVertical ? styles['vert-table'] : styles['hori-table']}>
        {isVertical || (
          <Wrapper
            wrapper={children => (
              <thead>
                <tr>{children}</tr>
              </thead>
            )}
          >
            {columns.map(({ title, dataIndex, render }, index) => (
              <th key={render ? index : dataIndex}>{title}</th>
            ))}
          </Wrapper>
        )}
        <Wrapper wrapper={isVertical ? x => x : children => <tbody>{children}</tbody>}>
          {pageDataSource.reduce((base, data, index) => base.concat(<GenerateRow columns={columns} valuesMap={valuesMap} transformArrays={transformArrays} expandedRowRender={expandedRowRender} generatePropsPerRow={generatePropsPerRow} dataSource={data} parentIndex={index} key={String(index)} />), [])}
        </Wrapper>
      </table>
      { pagesNumbers.length > 1 && (
        pagesNumbers
          .map((_, n) => <button type="button" onClick={() => setPaginationConfig({ start: perPage * n, offSet: (n + 1) * perPage, currentPageNumber: n })}>{n + 1}</button>)
          .concat(pagesNumbers.length - 1 > currentPageNumber ? <button type="button" onClick={() => setPaginationConfig({ start: perPage * (currentPageNumber + 1), offSet: (currentPageNumber + 2) * perPage, currentPageNumber: currentPageNumber + 1 })}>next</button> : [])
      )}
    </>
  );
}
