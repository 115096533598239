import React from 'react';
import { FormField } from '../FormFields/FormFieldsHelpers';
import DateField from '../DateField/DateField';
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
export default function RequestDate(props) {
  return (
    <FormField
      {...props}
      render={({ input, error }) => (
        <DateField
          error={error}
          dates={props.inputProps.allowedValues}
          input={input}
          {...props}
        />
      )}
    />
  );
}
