import React, {
  useLayoutEffect, useRef, useState, useMemo,
} from 'react';
import styles from './FormFields.module.scss';

export default function Parent({ inputProps, children }) {
  const { label, uuid } = inputProps;
  const [hide, setHide] = useState(false);
  const childrenRef = useRef();

  useLayoutEffect(() => {
    if (childrenRef.current.children.length) setHide(false);
    else setHide(true);
  }, [children]);
  return (
    <section
      className={`${styles['group-fields']} ${
        hide ? styles['display-none'] : ''
      }`}
      key={uuid}
    >
      <h3>{label}</h3>
      <div ref={childrenRef}>{children}</div>
    </section>
  );
}
