const ar = {
  translation: {
    title: 'الدراسة في مصر',
    auth: {
      registerTitle: 'إنشاء حساب',
      register: 'إنشاء الحساب',
      loginTitle: 'تسجيل الدخول',
      login: 'تسجيل',
      logout: 'تسجيل الخروج',
      loginError: 'البريد الإلكتروني أو كلمة السر غير صحيحة',
      registrationError: 'حدث خطأ ما',
    },
    certificateAdd: 'أضف إعدادات الشهادة',
    coordinationAdd: 'أضف إعدادات التنسيق',
    equationAdd: 'أضف إعدادات المعادله',
    addCoordinationAndFaculties: 'أضف إعدادات التنسيق والكليات',
    addPosGraduateAndFaculties: 'أضف إعدادات الدراسات العليا والكليات',
    postGraduateAdd: 'أضف للدرجات العليا',
    feesAdd: 'أضف لرسوم الخدمات',
    effectiveInformationAdd: 'أضف معلومه إثرائيه',
    periodsAdd: 'أضف للفترات التدريبية',
    periodsEdit: 'تعديل الفترات التدريبية',
    certificateEdit: 'تعديل إعدادات الشهادة',
    coordinationEdit: 'تعديل إعدادات التنسيق',
    equationEdit: 'تعديل إعدادات المعادله',
    appointmentsEdit: 'تعديل إعدادات مواعيد الإختبارات',
    appointmentsAdd: 'أضف لمواعيد الإختبارات',
    feesEdit: 'تعديل الرسوم',
    effectiveInformationEdit: 'تعديل المعلومات الأثرائيه',
    paymentEffectiveInformationEdit: 'تعديل المعلومات الإثرائية للدفع',
    candidacyLimitAdd: 'إضافة الحد الأقصى ',
    candidacyLimitEdit: 'تعديل الحد الأقصى ',
    signatureEdit: 'تعديل التوقيع',
    signatureAdd: 'تعديل التوقيع',
    postGraduateEdit: 'تعديل الدرجات العليا',
    arabicDepProgramsAdd: 'أضف إعدادات البرنامج',
    arabicDepProgramsEdit: 'تعديل اعدادات البرنامج',
    arabicSkillsAdd: 'أضف مهارات البرنامج',
    arabicSkillsEdit: 'تعديل مهارات البرنامج',
    arabicLevelsAdd: 'أضف مستويات البرنامج',
    arabicLevelsEdit: 'تعديل مستويات البرنامج',
    trainingEdit: 'تعديل التدريب',
    trainingAdd: 'أضف تدريب',
    appSummary:
      'ادرس في مصر! المغامرة التعليمية الجديدة التي تصنع الفرق كله - لديك # 1 للذهاب إلى مكان للتعليم والمرح. نحن نوفر لك سهولة الوصول إلى جميع كيانات التعليم العالي في مصر وكذلك تسهيل الصعود إلى الطائرة من خلال مجموعة شاملة من الخدمات. ابق على اتصال واحصل على عروضك التعليمية الآن!',
    'files-count-limit': 'عدد الملفات تخطى العدد المسموح به',
    'files-missing': 'لم يتم اختيار ملف',
    'file-over-size': 'حجم الملف تخطى الحجم المسموح',
    'wrong-later': 'حدث خطأ ما, برجاء المحاولة فى وقت لاحق',
    'error-wrong': 'حدث خطأ ما',
    'file-type-not-allowed': 'نوع ملف غير مسموح به',
    accepted: 'مقبول',
    excellent: 'ممتاز',
    good: 'جيد',
    empty: 'لا توجد بيانات',
    connectionError: 'حدث خطأ بالأتصال',
    ECONNABORTED: 'تم قطع الاتصال... برجاء المحاولة مره اخري',
    snap: 'عذراَ',
    errorMessage: 'حدث خطأ ما.',
    notFoundBackButton: 'عودة إلى الصفحة الرئيسية',
    requestConfirm:
      'إنك تضمن بموجب هذه البنود و الشروط و أنك قادر و مؤهل على الدخول و إبرام هذه الشروط و الاحكام',
    addanotherparty: 'إضافة طرف اول اخر',
    tableHint: 'شخص لا يمكن اضافته على البطاقة التموينية',
    noVehsWasFound: 'لا توجد مركبات',
    selectedFinesSum: 'اجمالى مخالفاتك الحالية',
    authentication: {
      sso: 'الدخول بالهوية الرقمية',
      processing: 'جاري تسجيل الدخول',
      failure: 'فشلت محاولة تسجيل الدخول',
      redirectLogin: 'العودة إلى  تسجيل الدخول',
    },
    app: {
      contrast: 'حدة الألوان',
      grayScale: 'درجة الرمادي',
    },
    family: {
      children: 'أبناء',
      maleChildren: 'أبناء',
      femaleChildren: 'بنات',
      wife: 'زوجة',
    },
    notifications: {
      header: 'التنبيهات',
      allNotifications: 'جميع التنبيهات',
      latestNotifications: 'اخر التنبيهات',
    },
    categories: {
      header: 'أو يمكنك الاختيار من حزم الخدمات الإلكترونية',
    },
    services: {
      header: 'خدمات تهمني',
      termsAgreement: 'هل توافق علي الشروط والاحكام',
      enterService: 'الدخول للخدمة',
      requestSent: 'تم ارسال طلبك بنجاح',
      requestSentLabelBefore: 'رقم الطلب هو',
      requestSentLabelAfter:
        'سيتم مراجعة طلبك، يمكنك متابعة الطلب من قائمة طلباتى أو العودة للصفحة الرئيسية',
    },
    genericStrings: {
      readMoreButton: 'قراءة المزيد',
      home: 'الرئيسية',
      myOrders: 'طلباتى',
      name: 'الاسم',
      address: 'العنوان',
      phoneNumber: 'رقم الهاتف',
      useAnotherAddress: 'استخدام عنوان آخر',
      confirmToContinue: 'يرجى الموافقة على كافة الشروط للاستكمال',
    },
    validation: {
      invalidTestDate: 'يجب أن يكون بحد اقصي خلال الاسبوع الاول من بداية المستوى و بعد موعد بداية الفترة',
      invalidStringLength: '{{label}} يجب أن يتراوح بين {{min}} و {{max}} أحرف',
      minStringLength: '{{label}} يجب أن يتكون من {{min}} أحرف علي الأقل',
      maxStringLength: '{{label}} يجب أن يتكون من {{max}} أحرف علي الأكثر',
      required: 'مطلوب',
      customInvalid: 'غير صحيح',
      minString: 'أقل من العدد المسموح به من الاحرف',
      maxString: 'أكثر من العدد المسموح به من الاحرف',
      minNumber: 'يجب ان يتراوح بين',
      maxNumber: 'يجب ان يتراوح بين',
      minChar: 'يجب ان يتراوح بين',
      maxChar: 'يجب ان يتراوح بين',

      minNumberExclusive: 'غير صحيح',
      maxNumberExclusive: 'غير صحيح',
      minDate: 'غير صحيح',
      maxDate: 'غير صحيح',
      badDate: 'غير صحيح',
      minCount: 'غير صحيح',
      maxCount: 'غير صحيح',
      noDecimal: 'غير صحيح',
      notAllowed: 'غير صحيح',
      expectedType: 'غير صحيح',
      invalid: 'غير صحيح',
      invalidEmail: 'غير صحيح',
      invalidPassword:
        'يجب ان تكون مزيج من الأحرف الكبيرة والصغيرة والأرقام والرموز',
      invalidPasswordLength: 'يجب أن يكون على الأقل 8 حروف',
      isNotArabic: 'يجب أن يحتوي على حروف عربية فقط',
      isNotEnglish: 'يجب أن يحتوي على حروف انجليزيه فقط',
      isNotFrench: 'يجب أن يحتوي على حروف فرنسيه فقط',
      isNotAlpha: 'يجب أن يحتوي على حروف فقط',
      isNotAlphanumeric: 'يجب أن يحتوي على حروف أو أرقام',
      isNotNumber: 'يجب أن يحتوي على أرقام فقط',
      isNotValidPhone: 'يجب أن يكون 11 رقم و يبدأ بأرقام 01',
      invalidAge: 'تاريخ الميلاد غير صحيح',
      invalidIssueDate: 'تاريخ إصدار جواز السفر غير صحيح',
      invalidExpirationDate: 'تاريخ إنتهاء جواز السفر غير صحيح',
      'unique error': 'لا يمكن تكراره',
      'unique error with other field': 'لا يمكن تكراره مع المدخلات الأخرى',
      'alike error': 'رقم ثابت للمستوى الواحد',
      unique: 'تم إدخاله من قبل',
      termsAndConditions: 'يجب أن توافق على الشروط و الأحكام',
      generalError: 'حدث خطأ ما',
      invalidYear: 'يجب ان تتراوح بين 1950 و 2050',
      invalidEndDate: 'يجب أن يكون أكبر من تاريخ البداية',
      invalidAtMost3Digits: 'يجب ان ألا تقل عن الصفر وان تكون على الاكثر 3 أرقام',
      invalidAtMost5Digits: 'يجب ان ألا تقل عن الصفر وان تكون على الاكثر 5 أرقام',
      invalidTwoDigitsOrChars: 'بحد أقصى رقمين أو حرفين',
      invalidFiveDigitsOrChars: 'بحد أقصي خمس أرقام أو حروف أو رموز',
      invalidMoreThan4: 'يقبل أرقام فقط ولا يزيد عن 4',
      invalidPercentage: 'يجب ان يكون نسبة مئوية',
      invalidAlphanumeric30:
        'بجب ان يتكون من حروف و ارقام و لا يزيد عن 30 حرف و رقم',
      uniqueOptionalSubjects: 'يجب أن تختلف عن المواد الأساسية',
      invalidGrades: 'يجب ان يكون الحد الادنى اقل من الحد الأعلى',
      invalidSubjects1: 'يجب أن يكون عددها 1 مواد',
      invalidSubjects2: 'يجب أن يكون عددها 2 مواد',
      invalidSubjects5: 'يجب أن يكون عددها 5 مواد',
      invalidSubjects3: 'يجب أن يكون عددها 3 مواد',
      invalidSubjects9: 'يجب أن يكون عددها على الأقل 9 مواد',
      invalidSubjects8: 'يجب أن يكون عددها على الأقل 8 مواد',
      invalidSubjects6: 'يجب أن يكون عددها على الأقل 6 مواد',
      invalidSubjects7: 'يجب أن يكون عددها على الأقل 7 مواد',
      invalidLevelNumber: 'يجب أن ألا يزيد عن عدد المستويات',
      invalidLevelCount: 'يتم ادخاله بعد ادخال عدد المستويات',
      'must-not-be-float': 'يجب ان يكون رقم صحيح',
      isNotAlphaNumericFactor: 'يقبل حرف واحد أو أرقام بحد أقصى 3 أرقام',
      invalidLevelStartDate: 'يجب أن يكون ما بين بداية و نهاية الفترة',
      invalidLevelEndDate: 'يجب أن يكون ما بين بداية المستوي و نهاية الفترة',
      invalidLevelDate: 'يجب أن تبدأ و تنتهي مابين بداية و نهاية الفترة',
      invalidFactorCert11: 'يقبل حروف لغة عربية و رموز +/-',
      invalidOptionalMax: 'يجب الا تقل عن قيمة عدد اعلى مواد اختيارية',
      invalidSubjectsOL: 'يجب ألا يقل عدد مواد كل تخصص عن 8 مواد',
      invalidSubjectsALAS: 'يجب ألا يقل عدد مواد كل تخصص عن 3 مواد منهم 2 للمستوى AS و واحدة للمستوى AL',
      invalidSubjectsLevel12: 'يجب ألا يقل عدد مواد الفصل ال 12 عن 4 مواد',
      invalidSat2SubjectObligatory: 'يجب أن تكون المادة الالزامية مادة واحدة فقط',
      invalidSubjectsUniqueLevels: 'يجب ألا تتكرر المواد خلال الصف الدراسي الواحد',
      invalidLevelsFactors: 'يجب اضافة معاملات للمستويات التى تحتوي على مواد دراسية',
      invalidOptionalWithObligatory: 'يجب ان تختلف المواد الاختيارية عن الالزامية',
      invalidFactorNameCertQ: 'اسم المعامل عبارة عن رقم يتراوح من 24 الى 45',
      invalidAdvancedSubjectsObligatory: 'يجب ألا تتواجد الا في حالة التخصص علمي علوم او علمي رياضة',
      invalidAdvancedSubjectsOptional: 'يجب ألا تقل عن ثلاث مواد في حالة التخصص أدبي',
      invalidAdvancedSubjectsObligatoryExistence: 'يجب أن تتواجد في حالة التخصص علمي علوم او علمي رياضة',
      invalidSubjectsLengthWitMinimal: 'يجب ألا تقل عن الحد الأدنى للمواد المطلوبة من الطالب',
      invalidSubjectsLengthWitMax: 'يجب ألا تقل عن الحد الأقصى للمواد المطلوبة من الطالب',
      invalidSat2SubjectsExistence: 'يجب الا يتم الادخال الا اذا كان التخصص علمي علوم أو علمي رياضة',
      uniqueMotherLanguage: 'يجب ان تختلف عن المواد الأساسية و الاختيارية',
    },
    general: {
      backToHome: 'الصفحة الرئيسية',
      errorBoundary: 'حدث خطأ ما',
      errorBoundaryDetails: 'يمكنك إعادة المحاولة أو العودة إلى الرئيسية',
      reload: 'إعادة المحاولة',
      resendVerification: 'أعد إرسال رسالة التأكيد',
      letter: 'حرف',
      pagenotfound: 'الصفحة غير موجودة!',
      search: 'بحث',
      reset: 'مسح المدخلات',
      searchPlaceholder: 'البحث بواسطة {{title}}',
    },
    arabicDepLookups: {
      programs: 'البرامج',
      levels: 'المستويات',
      branches: 'الفروع',
      skills: 'المهارات',
      grades: 'التقديرات',
      ArabicScholarShipPeriods: 'فترات المنح',
    },
    header: {
      generalLists: 'القوائم العامة',
      genralSettings: 'الاعدادات العامة',
      feesSettings: 'اعدادات الرسوم',
      effectiveInformationSettings: 'المعلومات الإثرائيه',
      employeeSignatures: 'التوقيعات',
      arabicDep: 'قسم اللغة العربية',
      arabicLookups: 'القوائم المساعدة للغة العربية',
      arabicProgramsSettings: 'اعدادات برامج اللغة العربية',
      arabicSkillsSettings: 'اعدادات مهارات اللغة العربية',
      arabicLevelsSettings: 'اعدادات مستويات اللغة العربية',
      coursePeriodsSettings: ' اعدادات الفترات التدريبية',
      testTimeSettings: 'اعدادات مواعيد إختبارات تحديد المستوى',
      training: 'التدريب/الدراسة قصيرة المدة',
      trainingLookups: 'القوائم المساعدة للتدريب',
      trainingSettings: 'اعدادات التدريب',
      university: 'التعليم الجامعي',
      universityLookups: 'القوائم المساعدة للتعليم الجامعي',
      equationsSettings: 'إعدادات المعادلات ',
      certificates: 'الشهادات الدراسية',
      coordination: 'اعدادات التنسيق للشهادة الثانوية',
      postgraduate: 'الدراسات العليا',
      postgraduateLookups: 'القوائم المساعدة للدراسات العليا',
      postgraduateSettings: 'اعدادات الدراسات العليا',
      logout: 'تسجيل الخروج',
      candidacyLimit: ' الحد الأقصى للترشح',
      PaymentEffectiveInformationSettings: 'المعلومات الإثرائيه للدفع',
    },
    footer: {
      quickLinks: 'روابط سريعة',
      contactUs: 'اتصل بنا',
      address: '7 ش د. ابراهيم ابو النجا، حي السفارات، مدينة نصر.',
      governorate: 'القاهرة، مصر. 11765',
      hotline: 'الخط الساخن',
      phone: 'هاتف',
      fax: 'فاكس',
      whatsapp: 'واتس آب فقط',
      email: 'البريد الإلكتروني',
      copyRights: 'جميع الحقوق 2020 محفوظة – مبادرة ادرس في مصر – وزارة التعليم العالي والبحث العلمي',
      phoneNumber: '20223516693+',
      faxNumber: '20223516826+',
      whatsappNumber: '201098257891+',
    },
  },
};

export default ar;
