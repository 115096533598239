import Loki from 'lokijs';

const db = new Loki('lookups.db');

const cachedLookups = ['countries', 'specializations', 'certificates', 'studyTypes', 'universities', 'faculties', 'subjects', 'scientificDegrees', 'scientificDegreesSpecialization'];

cachedLookups
  .map(name => [name, `${name}_raw`].map(slug => db.addCollection(slug)));

export const setDataToCache = ({ name: lookupName, isRaw, options }) => {
  if (!cachedLookups.includes(lookupName)) return null;
  const collection = db.getCollection(isRaw ? `${lookupName}_raw` : lookupName);
  collection.findAndRemove();
  return collection.insert(options);
};

export const getDataFromCache = ({ name: lookupName, isRaw }) => {
  if (!cachedLookups.includes(lookupName)) return null;
  const collection = db.getCollection(isRaw ? `${lookupName}_raw` : lookupName);
  return collection.count() && collection.find();
};

export const clearDataFromCache = ({ name: lookupName }) => {
  if (!cachedLookups.includes(lookupName)) return null;
  [`${lookupName}_raw`, lookupName].forEach((name) => {
    const collection = db.getCollection(name);
    collection.findAndRemove();
  });
};
