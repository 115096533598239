import get from 'lodash/fp/get';
import authAPI from '../../APIs/authAPI';
import actionStatuses from './actionStatuses';
import { removeAuthentication } from '../../util/helpers';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_MODEL_DATA = 'SET_MODEL_DATA';
export const SET_AUTH_META = 'SET_AUTH_META';

const setToLocalStorage = ({ profile, accessToken, xCsrfToken }) => {
  const { userSlug, id } = profile;
  localStorage.setItem('userData', profile);
  localStorage.setItem('userSlug', userSlug);
  localStorage.setItem('userId', id);
  localStorage.setItem('fullName', `${profile.firstName} ${profile.lastName}`);
  localStorage.setItem('accessToken', accessToken);
  // localStorage.setItem('x-csrf-token', xCsrfToken);
};

export const setModelData = ({ model, status, error }) => ({
  type: SET_MODEL_DATA,
  model,
  status,
  error,
});

export const setUserData = ({
  data, status, error, loading,
}) => ({
  type: SET_USER_DATA,
  data,
  status,
  error,
  loading,
});

export const setAccessToken = token => ({
  type: SET_ACCESS_TOKEN,
  token,
});

export const setAuthMeta = ({ data }) => ({
  type: SET_AUTH_META,
  data,
});

export const getUserModel = () => dispatch => authAPI.getUserModel()
  .then((model) => {
    dispatch(setModelData({
      model,
    }));
    localStorage.setItem('userSlug', model.userSlug);
    return model;
  }).catch((error) => {
    dispatch(setModelData({
      error,
    }));
    return Promise.reject();
  });

export const getAuthMeta = slug => dispatch => authAPI.getAuthMeta(slug)
  .then(({ data }) => {
    dispatch(setAuthMeta({
      data,
    }));
    return data;
  })
  .catch((error) => {
    dispatch(setAuthMeta({
      error,
    }));
    return Promise.reject();
  });

export const getUserData = profile => async (dispatch) => {
  dispatch(setUserData({ status: actionStatuses.START }));

  try {
    const { data } = await authAPI.getUserProfile();
    dispatch(setUserData({ data, status: actionStatuses.SUCCESS }));
  } catch (error) {
    const { response } = error;
    const errorData = get(response, 'data');
    dispatch(
      setUserData({
        status: actionStatuses.FAILED,
        error: errorData || error,
      }),
    );
  }
};

export const exchangeToken = (slug, token) => dispatch => authAPI.exchangeToken(slug, token)
  .then((response) => {
    const {
      headers: { 'x-csrf-token': csrfToken },
      data: { userId, profile },
    } = response;
    if (!csrfToken) return response;
    setToLocalStorage({ profile: { userSlug: slug, id: userId, ...profile }, accessToken: csrfToken });
    dispatch(getUserData(slug, response.data.id));
    dispatch(setAccessToken(csrfToken));
    window.location.href = '/';
  }).catch(error => Promise.reject());

export const login = (slug, userData) => (dispatch) => {
  dispatch(setUserData({ status: actionStatuses.START }));
  return authAPI
    .login(slug, userData)
    .then((response) => {
      const {
        data: { profile },
        headers: { 'x-csrf-token': accessToken },
      } = response;

      if (!accessToken) return response;
      setToLocalStorage({ profile, accessToken });
      dispatch(setUserData({ data: profile, status: actionStatuses.SUCCESS }));
      dispatch(setAccessToken(accessToken));
    })
    .catch((error) => {
      const { response } = error;
      const errorData = get(response, 'data');
      dispatch(
        setUserData({
          status: actionStatuses.FAILED,
          error: errorData || error,
        }),
      );
      return Promise.reject();
    });
};

export const logout = logoutUrl => dispatch => authAPI.logout()
  .then(() => {
    removeAuthentication();
    dispatch(setModelData({}));
    dispatch(setUserData({ data: {} }));
    dispatch(setAccessToken(''));
    if (logoutUrl) window.location.href = logoutUrl;
  })
  .catch((error) => {
    console.error(error);
    removeAuthentication();
  });
