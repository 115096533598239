import React from 'react';
import get from 'lodash/get';
import styles from './FormInput.module.scss';

const FormInput = ({ label, props }) => {
  const {
    optional, error, input, meta,
  } = props;

  const {
    placeholder, hidden, disabled, type,
  } = input;
  return (
    <div className={label !== 'سنة الشهادة' ? styles.inputContainer : ''}>
      <label
        className={`${!optional ? styles['field-required'] : ''} ${
          hidden ? styles['label-hidden'] : ''
        }`}
      >
        {label}
      </label>
      <br />
      <input
        {...input}
        className={`${error ? styles.hasError : ''} ${styles['custom-input']}`}
        placeholder={placeholder}
        hidden={hidden}
        type={type}
        disabled={disabled}
      />
    </div>
  );
};
export default FormInput;
