import React from 'react';
import styles from './table.module.scss';

const FileWithdrawalRatiosCycleDateColumn = ({ text = {}, record = {} }) => {
  const {
    dayOfCycle10, dayOfCycle50, dayOfCycle100, monthOfCycle10, monthOfCycle50,
    monthOfCycle100,
  } = record;

  return (
    <>
      <div>
        <p className={styles['no-margin']}>
          تاريخ بدأ نسبة 10%
        </p>
        <p className={styles['no-margin']}>
          {`${dayOfCycle10}/${monthOfCycle10}`}
        </p>
      </div>
      <div>
        <p className={styles['no-margin']}>
          تاريخ بدأ نسبة 50%
        </p>
        <p className={styles['no-margin']}>
          {`${dayOfCycle50}/${monthOfCycle50}`}
        </p>
      </div>
      <div>
        <p className={styles['no-margin']}>
          تاريخ بدأ نسبة 100%
        </p>
        <p className={styles['no-margin']}>
          {`${dayOfCycle100}/${monthOfCycle100}`}
        </p>
      </div>
    </>
  );
};
export default FileWithdrawalRatiosCycleDateColumn;
