/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import { Form } from 'react-final-form';
import EditOutlined from '@ant-design/icons/EditOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Spin from 'antd/lib/spin';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Table from '../../../Components/UIElements/Table/Table';
import CustomButton from '../../../Components/UIElements/Button';
import listsAPIs from '../../../APIs/lists_apis';
import styles from './Template2.module.scss';
import i18next from '../../../i18next/index';
import FormFields from '../../../Components/FormFields/FormFields';
import { showNotification } from '../../../util/helpers';

const extraTableProps = {
  generatePropsPerRow: row => (row.__odd__
    ? { className: styles.oddRow }
    : { className: styles.evenRow }),
};
const Template2 = ({
  form, slug, path, groupBy, addText, parentTitleAttribute, titleAttribute, title, relation, api, formatValuesFn, fallbackUrl, history, location: { state: mainData },
}) => {
  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [schema, setSchema] = useState({});
  const [loading, setLoading] = useState(true);
  const [editedItem, setEditedItem] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);

  const LISTS_APIS = useMemo(() => listsAPIs(api), [api]);

  // create an object with the options fields and their options
  const getOptionsFields = fieldsSchema => Object.entries(fieldsSchema).reduce((acc, [key, value]) => {
    const options = get(value, 'uniforms.options');
    if (options) return { ...acc, [key]: get(value, 'uniforms.options') };
    return acc;
  }, {});

  // format item data to change every option value with its label
  const formatItemData = useCallback((item, optionsFields) => Object
    .entries(item).reduce((acc, [key, value]) => {
      if (Object.keys(optionsFields).includes(key)) {
        const optionLabel = optionsFields[key]?.find(option => option.value === value)?.label ?? value;
        return { ...acc, [key]: optionLabel };
      }
      return { ...acc, [key]: value };
    }, {}), []);

  const formatItemsData = useCallback((newItems, formSchema) => {
    const optionsFields = getOptionsFields(formSchema);
    return newItems.map(item => formatItemData(item, optionsFields));
  }, [formatItemData]);

  // group items in the table by one attribute
  const groupItems = useCallback(itemsToGroup => Object.values(_groupBy(itemsToGroup, groupBy))
    .flatMap((group, groupIndex) => group.map((row, i) => (
      i === 0
        ? { ...row, __odd__: groupIndex % 2 }
        : { ...row, [groupBy]: '', __odd__: groupIndex % 2 }
    ))), [groupBy]);

  const checkCombinationUniqueness = useCallback((fieldsSchema, rowData, rowsItems) => {
    // get fields with the flag "uniqueCombination" in the fields schema
    const uniqueFields = Object.entries(fieldsSchema)
      .reduce((acc, [key, value]) => (value.uniqueCombination ? [...acc, key] : acc), []);

    if (uniqueFields.length === 0) return true;

    const isRowDataUnique = !rowsItems.some(
      item => uniqueFields.every(key => rowData[key] === item[key]),
    );
    if (!isRowDataUnique) {
      const fieldsLabels = uniqueFields.map(key => fieldsSchema[key].label);
      showNotification({
        type: 'error',
        message: 'خطأ في إدخال البيانات',
        description: `الحقول " ${fieldsLabels.join(' - ')} " لا يمكن تكرارها معا`,
      });
    }
    return isRowDataUnique;
  }, []);

  useEffect(() => { // this function should run only once
    (async () => {
      setLoading(true);
      const module = await import(`../../../FormSchemas/form-dynamic/${form}`);
      setSchema(await module.schema());
      const results = await LISTS_APIS.getAll(
        { parentValue: mainData.value, slug },
        { path },
      );
      setItems(results);
      setLoading(false);
    })();
  }, [groupItems, formatItemsData, mainData, form, slug, path, LISTS_APIS]);

  useEffect(() => {
    // reformat displayed items in the table whenever the items change (replace options fields values with their labels)
    const formattedItems = formatItemsData(items, schema);
    // group displayed items if it's required
    const finalItems = groupBy ? groupItems(formattedItems) : formattedItems;
    setDisplayedItems(finalItems);
  }, [items, formatItemsData, groupItems, schema, groupBy]);

  const handleAdd = async (data) => {
    if (!checkCombinationUniqueness(schema, data, items)) {
      return new Promise((_, reject) => reject());
    }
    try {
      setLoading(true);
      const result = await LISTS_APIS.add({
        ...(formatValuesFn ? formatValuesFn(data) : data),
        parentValue: mainData.value,
        path,
        slug,
      }, {
        params: { path },
      });
      setItems(prevItems => [...prevItems, result]);
      showNotification({
        type: 'success',
        message: `تمت إضافة ${title} بنجاح`,
      });
    } catch (err) {
      const { message } = err.response.data;
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: message,
      });
    } finally {
      setLoading(false);
    }
    return new Promise(resolve => resolve());
  };

  const handleEdit = async (data) => {
    try {
      const editedFields = Object.entries(schema).reduce(
        (acc, [key, value]) => (value?.tableEditable ? [...acc, key] : acc), [],
      );
      const editedValues = editedFields.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {});
      setLoading(true);

      await LISTS_APIS.edit(data.id, formatValuesFn ? formatValuesFn(editedValues) : editedValues, {
        params: { path },
      });

      const newItems = items.map((item) => {
        if (item.id !== data.id) return item;
        return { ...item, ...editedValues };
      });
      setItems(newItems);
      setEditedItem(null);
      showNotification({
        type: 'success',
        message: `تمت تعديل ${title} بنجاح`,
      });
    } catch (err) {
      const { message } = err.response.data;
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = id => async () => {
    try {
      setLoading(true);
      await LISTS_APIS.delete(id, { params: { path } });
      setItems(prevItems => prevItems.filter(item => item.id !== id));
      showNotification({
        type: 'success',
        message: `تمت حذف ${title} بنجاح`,
      });
      setDeletedItem(null);
    } catch (err) {
      const { message } = err.response.data;
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const getCols = tableSchema => Object.entries(tableSchema)
    .map(([key, { label, render }]) => (
      render ? { render, title: label } : { dataIndex: key, title: label }
    )).concat({
      title: 'الحذف و التعديل',
      render: (rowData) => {
        const hasEditableFields = Object.entries(schema).some(([key, value]) => value?.tableEditable);
        return (
          <div className={styles.actions}>
            {hasEditableFields && (
              <button type="button" onClick={() => setEditedItem(rowData)}>
                <EditOutlined />
              </button>
            )}
            <button type="button" onClick={() => setDeletedItem(rowData)}>
              <DeleteOutlined />
            </button>
          </div>
        );
      },
    });

  const getFixedValues = values => Object.entries(schema)
    .reduce((acc, [key, value]) => (value.fixed ? { ...acc, [key]: values[key] } : acc), {});

  const handleFormSubmission = async (values, { restart }) => {
    // using restart function couldn't happen inside the submitting function itself (handleAdd),
    // so it have to be in promise and it's used to clear the form after submission except for certain values (fixed values)
    handleAdd(values)
      .then(() => { restart(getFixedValues(values)); })
      .catch(() => {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles['card-body']}>
          {mainData && (
            <h2 className={styles.title}>
              {`${relation} ${mainData[parentTitleAttribute]}`}
            </h2>
          )}
          <Form
            onSubmit={handleFormSubmission}
            render={({
              handleSubmit,
              values,
              form: { change },
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormFields
                  data={{ fieldsSchema: schema }}
                  formValues={values}
                  changeFieldValue={change}
                />
                <div className={styles['submit-btn']}>
                  {!loading && (
                    <CustomButton type="submit">
                      {i18next.t(addText)}
                    </CustomButton>
                  )}
                </div>
              </form>
            )}
          />
          {loading ? (
            <div className={styles.spinner}>
              <Spin
                size="large"
                tip={loading ? 'برجاء الانتظار ...' : 'تحميل ...'}
              />
            </div>
          )
            : (
              <>
                <div className={styles.table}>
                  <Table
                    columns={getCols(schema)}
                    dataSource={displayedItems}
                    {...extraTableProps}
                  />
                </div>
              </>
            )}

          {!!fallbackUrl && (
            <div className={styles['buttons-container']}>
              <CustomButton secondary type="button" action={() => history.replace(`/${fallbackUrl}`)}> الرجوع للقائمة الرئيسية </CustomButton>
            </div>
          )}
        </div>
      </div>

      {deletedItem && !loading && (
        <Modal
          title={`حذف  ${deletedItem[titleAttribute]}`}
          visible={deletedItem}
          onOk={handleDelete(deletedItem.id)}
          onCancel={() => setDeletedItem(null)}
          confirmLoading={loading}
          footer={(
            <Button onClick={handleDelete(deletedItem.id)} className={styles.submitDelete}>
              تأكيد
            </Button>
          )}
        >
          <div>هل انت متاكد من حذف هذا السجل ؟</div>
        </Modal>
      )}

      {editedItem && !loading && (
        <Modal
          title={`تعديل  ${editedItem[titleAttribute]}`}
          visible={editedItem}
          onCancel={() => setEditedItem(null)}
          confirmLoading={loading}
          footer={() => null}
        >
          <div>
            <Form
              onSubmit={handleEdit}
              initialValues={editedItem}
              render={({
                handleSubmit,
                dirtyFieldsSinceLastSubmit,
                ...rest
              }) => {
                const editingSchema = Object.entries(schema).reduce(
                  (acc, [key, value]) => (value?.tableEditable ? { ...acc, [key]: value } : acc), {},
                );
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <FormFields
                      data={{
                        fieldsSchema: editingSchema,
                      }}
                    />
                    <div className={styles['edit-submit-btn']}>
                      <CustomButton type="submit"> تعديل </CustomButton>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Template2;
