import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { removeAuthentication } from './helpers';

const instance = axios.create({
  baseURL: window.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const lang = localStorage.getItem('lang');
  const accessToken = localStorage.getItem('accessToken');
  // const xCsrfToken = localStorage.getItem('x-csrf-token');
  return {
    ...config,
    params: { ...config.params },
    headers: {
      ...config.headers,
      'Access-Control-Allow-Origin': '*',
      'Accept-Language': lang,
      'x-csrf-token': accessToken || '',
    },
  };
});

const sessionDestroyer = (statusCode) => {
  if (localStorage.getItem('accessToken') && [401, 403].includes(statusCode)) {
    window.location.href = '/';
    removeAuthentication();
  }
};
instance.interceptors.response.use(response => response, (error) => {
  sessionDestroyer(error.response && error.response.status);
  return Promise.reject(error);
});

export default instance;
