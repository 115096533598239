import React, { useState, useEffect, useRef } from 'react';
import _concat from 'lodash/concat';

import CustomTable from '../../Components/table/index';
import ItemActions from '../ItemActions/ItemActions';

import { getColumnSearchProps } from '../../Components/table/tablesHelpers';
import FileWithdrawalRatiosCycleDateColumn from './fileWithdrawalRatiosCycleDateColumn';

const TableLists = ({
  lookupType, data, label, handleDelete, loading, handleEdit, pagination,
}) => {
  const [schema, setSchema] = useState(null);
  const [columns, setColumns] = useState([]);
  const [schemaLoading, setSchemaLoading] = useState(true);

  const searchInputRef = useRef(null);

  useEffect(() => {
    const getSchema = async () => {
      const module = await import(`../../FormSchemas/${lookupType}`);
      setSchema(await module.schema());
      setSchemaLoading(false);
    };
    getSchema();
  }, [lookupType]);

  useEffect(() => {
    if (schema) {
      let array = schema && Object.entries(schema).map(item => (item[1].metaData));
      if (lookupType === 'fileWithdrawalRatiosCycle') {
        array = [{
          title: ' ',
          render: (text, record) => (
            <span>
              تواريخ بدأ سحب الملف
            </span>
          ),
        },
        {
          title: 'شهر / يوم',
          render: (text, record) => (
            <FileWithdrawalRatiosCycleDateColumn text={text} record={record} />
          ),
        }];
      }
      setColumns(_concat(
        array.map(column => ({
          ...column,
          ...getColumnSearchProps({
            column, searchInputRef,
          }),
        })).filter(item => !item.header),
        // eslint-disable-next-line no-nested-ternary
        ['countries'].includes(lookupType) ? [] : ['fileWithdrawalRatiosCycle'].includes(lookupType) ? [{
          title: ' التعديل',
          render: (text, record, index) => (
            <ItemActions
              lookupType={lookupType}
              label={label}
              item={record}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ),
        }] : [{
          title: 'الحذف او التعديل',
          render: (text, record, index) => (
            <ItemActions
              lookupType={lookupType}
              label={label}
              item={record}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          ),
        }],
      ));
    }
  }, [handleDelete, handleEdit, label, lookupType, schema]);

  return (
    <CustomTable pagination={pagination} loading={loading || schemaLoading} columns={columns} data={data} />
  );
};

export default TableLists;
