import React from 'react';
import get from 'lodash/get';
import { Field } from 'react-final-form';
// import { formatErrorText, transformDigitsToEnglish } from '../RequestForm/helpers';
import styles from './FormInput/FormInput.module.scss';
import {
  formatErrorText,
  handleVariationValidation,
  regExpValidator,

  validateConditionalFields,
  handleCustomRegex,
  isFloat,
} from '../../util/helpers';

const regexErrorsDict = {
  alphanumeric: 'isNotAlphanumeric',
  email: 'invalidEmail',
  alphaAr: 'isNotArabic',
  alphaEn: 'isNotEnglish',
  alphaFr: 'isNotFrench',
  alpha: 'isNotAlpha',
};

const validateField = async (args) => {
  const { value, formValues, inputProps: field } = args;
  const {
    optional,
    type,
    regExp,
    min,
    max,
    numberType,
    name,
    match,
    customRegex,
    validate,
    render,
  } = field;
  if (!optional) {
    if (!value || (type === 'checkbox' && !value.length)) return 'required';
  }
  if (match) {
    if (get(formValues, match) !== value) return 'noMatch';
  }
  if (type === 'Number') {
    if (numberType !== 'float' && isFloat(value)) return 'must-not-be-float';
  }
  if (min || max || get(render, 'minEntries')) {
    const error = handleVariationValidation({ ...field, value });
    if (error) return error;
  }
  if (regExp) {
    if (!regExpValidator(regExp, value)) return get(regexErrorsDict, regExp, 'invalid');
  }
  if (customRegex) {
    const error = handleCustomRegex(customRegex, value);
    if (error) return { msg: error, fromCustomRegex: true };
  }
  if (validate) {
    const error = await validate({ value, formValues });
    if (error) return error;
  }
};

export const Condition = ({
  condition, name, change, children,
}) => {
  if (condition) return children();
  change(name, undefined);
  return null;
};

export const FormField = ({
  inputProps,
  formProps,
  rawSchema,
  formValues,
  render: inputRender,
  ...other
}) => {
  const {
    name,
    label,
    hidden,
    uniforms: { placeholder, type: inputtype } = {},
    editable,
    conditionalField,
    type,
    regExp,
  } = inputProps;
  const { changeFieldValue: change } = formProps;
  const {
    display,
    wrapperClass = '',
    noLabel,
    noWrapper,
    noErrorHandling,
  } = other;
  const disabled = !editable;

  // if (name === 'confirmPassword') {
  //   const validPassword = regExpValidator('password', _get(formValues, 'password'));
  //   disabled = !validPassword;
  // }
  const changeProps = editable ? {} : { onChange: x => x };
  if (conditionalField) {
    const shouldShow = validateConditionalFields(inputProps, formValues);
    if (!shouldShow) {
      change(name, undefined);
      return null;
    }
  }
  if (name.startsWith('h1')) {
    return <h1>{label}</h1>;
  }
  return !hidden ? (
    <Field
      name={name}
      validate={value => validateField({
        value,
        formValues,
        inputProps,
      })}
      type={type}
      render={({ meta, input, ...renderProps }) => {
        const error = meta.touched
          && (meta.error || (!meta.dirtySinceLastSubmit && meta.submitError));
        return (
          <>
            {inputRender({
              meta,
              input: {
                ...input,
                ...changeProps,
                placeholder,
                hidden,
                type,
                inputtype,
                disabled,
              },
              ...renderProps,
              error,
            })}
            {
              // to set position absolute and remove condition
              !noErrorHandling ? (
                <div
                  className={`${styles['field-error']} ${
                    !(error && !noErrorHandling) ? styles['error-hidden'] : ''
                  }`}
                >
                  <span>{formatErrorText(error, label, inputProps)}</span>
                  {type !== 'checkbox' && (
                    <i
                      className={`fa fa-exclamation ${styles['error-icon']}`}
                    />
                  )}
                </div>
              ) : null
            }
          </>
        );
      }}
    />
  ) : null;
};
