import React from 'react';
import Table from 'antd/lib/table';

import Config from '../../util/ConfigProvider';
import './tables-helpers.module.scss';

const CustomTable = ({
  columns, data, loading, pagination,
}) => (

  <Config>
    <Table pagination={{ ...pagination, showTitle: false }} loading={loading} columns={columns} dataSource={data} />

  </Config>

);

export default CustomTable;
