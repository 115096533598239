import { axios } from '../util';

const authAPI = {
  getUserModel() {
    return axios
      .get('/api/dynamic_users/findOne', {
        params: { filter: { where: { userSlug: 'configurator' } } },
      })
      .then(({ data }) => data);
  },
  login(slug, userData) {
    return axios.post('/api/configurator/login', userData);
  },
  logout() {
    const userSlug = localStorage.getItem('userSlug');
    const access_token = localStorage.getItem('accessToken');
    if (!userSlug) {
      return Promise.reject(new Error('no userSlug in localstorage'));
    }
    return axios.post('/api/configurator/logout', { access_token });
  },
  getAuthMeta(slug) {
    return axios.get(`/api/${slug}/getAuthenticationMeta`);
  },
  exchangeToken(slug, token) {
    return axios.post(`/api/${slug}/exchangeToken`, { token });
  },
  getUserProfile() {
    return axios.get('/users/profile');
  },
};

export default authAPI;
