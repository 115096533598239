import React from 'react';
import HeaderContent from '../Header';
import styles from './Layout.module.scss';
import Routes from '../../Routes/Routes';
import Footer from '../Footer';

const AppLayout = props => (
  <div className={styles['app-layout']}>
    <HeaderContent />

    <Routes {...props} />

    <Footer />
  </div>
);
export default AppLayout;
