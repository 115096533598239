import React from 'react';
import Result from 'antd/lib/result';
import styles from './ErrorFallback.module.scss';
import CustomButton from '../UIElements/Button/index';

import i18next from '../../i18next';

const ErrorFallBack = () => (
  <div className={styles.container}>
    <div className={styles.card}>
      <div className={styles['card-body']}>
        <div className={styles.feedback}>
          <Result
            status="error"
            title={(
              <div className={styles['response-code']}>
                {i18next.t('general.errorBoundary')}
              </div>
            )}
            subTitle={(
              <div className={styles['response-message']}>
                {i18next.t('general.errorBoundaryDetails')}
              </div>
            )}
            extra={(
              <div className={styles['boundary-buttons']}>
                <CustomButton
                  color="black"
                  type="button"
                  action={() => {
                    window.location.href = '/';
                  }}
                >
                  {i18next.t('general.backToHome')}
                </CustomButton>
                <CustomButton
                  type="button"
                  color="black"
                  action={() => window.location.reload()}
                >
                  {i18next.t('general.reload')}
                </CustomButton>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ErrorFallBack;
