import React from 'react';
import { ConfigProvider } from 'antd';
import ar_EG from 'antd/lib/locale-provider/ar_EG';
import en_US from 'antd/lib/locale-provider/en_US';

const Config = (props) => {
  const { children } = props;
  return (
    <ConfigProvider
      locale={ar_EG}
      direction="rtl"
    >
      {children}
    </ConfigProvider>
  );
};
export default Config;
