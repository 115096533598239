/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import TwitterOutlined from '@ant-design/icons/TwitterOutlined';
import FacebookFilled from '@ant-design/icons/FacebookFilled';
import LinkedinFilled from '@ant-design/icons/LinkedinFilled';
import YoutubeFilled from '@ant-design/icons/YoutubeFilled';
import InstagramFilled from '@ant-design/icons/InstagramFilled';
import styles from './Footer.module.scss';
import i18next from '../../i18next';

const Footer = ({ text, secondary, history }) => (
  <footer className={styles['footer-container']}>
    <div className={styles['footer-body']}>
      <div className={styles.summary}>
        <img alt="study in egypt logo" className={styles['footer-image']} src="/images/Study-in-Egypt-logo_w_ar.png" />
        <div>
          {i18next.t('appSummary')}
        </div>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/siewafaden">
              <FacebookFilled />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/siewafeden/">
              <InstagramFilled />

            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/siewafeden">
              <TwitterOutlined />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/study-in-egypt/">
              <LinkedinFilled />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCI7mRt7JeIc9zAADZ-lA3ug">
              <YoutubeFilled />

            </a>
          </li>
        </ul>
      </div>
      <div className={styles['call-us']}>
        <h2>{i18next.t('footer.contactUs')}</h2>
        <ul>
          <li>
            {i18next.t('footer.address')}
          </li>
          <li>
            {i18next.t('footer.governorate')}
          </li>
          <li>
            {i18next.t('footer.hotline')}
            {' '}
            : 19064
          </li>
          <li>
            {i18next.t('footer.phone')}
            :
            {' '}
            {i18next.t('footer.phoneNumber')}
          </li>
          <li>
            {i18next.t('footer.fax')}
            :
            {' '}
            {i18next.t('footer.faxNumber')}
          </li>
          <li>
            {i18next.t('footer.whatsapp')}
            :
            {' '}
            {i18next.t('footer.whatsappNumber')}
          </li>
          <li>
            {i18next.t('footer.email')}
            : info@study-in-egypt.gov.eg
          </li>
        </ul>
      </div>
    </div>
    <div className={styles['footer-copyrights']}>{i18next.t('footer.copyRights')}</div>

  </footer>
);

export default Footer;
