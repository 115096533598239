import React from 'react';
import Input from 'antd/lib/input';
import { SearchOutlined } from '@ant-design/icons';
import styles from './tables-helpers.module.scss';
import CustomButton from '../UIElements/Button';
import i18next from '../../i18next';

export function TrueIcon() {
  return <i className={`${styles['true-icon']} fa fa-check`} />;
}

export function FalseIcon() {
  return <i className={`${styles['false-icon']} fa fa-times`} />;
}

const handleSearch = ({
  confirm,
}) => {
  confirm();
};

const handleReset = ({ clearFilters }) => {
  clearFilters();
};

export const getColumnSearchProps = ({
  column: {
    dataIndex, onFilter, title, filters,
  }, searchInputRef,
}) => (filters ? ({
  onFilter: (value, record) => record[dataIndex] === value,
}) : ({
  filterDropdown: ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div className={styles['filter-container']}>
      <Input
        ref={searchInputRef}
        placeholder={i18next.t('general.searchPlaceholder', { title })}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch({
          confirm,
        })}
        className={styles['search-input']}
      />
      <div className={styles['btns-container']}>
        <CustomButton
          type="button"
          secondary
          action={() => handleSearch({
            confirm,
          })}
        >
          {i18next.t('general.search')}
        </CustomButton>
        <CustomButton
          type="button"
          action={() => handleReset({ clearFilters })}
        >
          {i18next.t('general.reset')}
        </CustomButton>
      </div>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined className={filtered ? styles['filter-icon'] : ''} />,
  onFilter: onFilter || ((value, record) => (record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '')),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInputRef.current.select(), 100);
    }
  },
}));
