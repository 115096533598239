/* eslint-disable radix */
export const validateFactorName = ({ value, formValues: { equation } }) => {
  if (['2', '9'].includes(equation)) {
    if (!value.match(/^.{1,5}$/)) return 'invalidFiveDigitsOrChars';
  } else if (['20'].includes(equation)) {
    if (!value.match(/^[1-9][0-9.]*$/) || parseFloat(value) > 45 || parseFloat(value) < 24) {
      return 'invalidFactorNameCertQ';
    }
  }
  //  else if (['9'].includes(equation)) {
  //   if (!value.match(/^[0-9][0-9.]*$/) || parseInt(value) > 4) {
  //     return 'invalidMoreThan4';
  //   }
  // }
};

export const validateFactorValue = ({ value, formValues: { equation } }) => {
  if (['2', '9', '19', '13', '15', '11', '20', '22', '27', '29'].includes(equation)) {
    if (!value.match(/^[1-9][0-9.]*$/) || parseFloat(value) > 100) {
      return 'invalidPercentage';
    }
  }
  if (['28'].includes(equation)) {
    if (!value.match(/^[1-9][0-9.]*$/)) {
      return 'isNotNumber';
    }
  }
};

export const validateGrade = ({ value }) => {
  if (!value.match(/^[a-zA-Z\u0621-\u064A][a-zA-Z\u0621-\u064A\s]{0,14}$/)) {
    return 'invalid';
  }
};

export const validateFromGradeTo = ({ value }) => {
  if (!value.match(/^[0-9][0-9.]*$/) || parseInt(value) > 100) {
    return 'invalidPercentage';
  }
};
