/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';

import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import LookupAction from '../LookupAction/LookupAction';
import LOOKUPS_APIS from '../../APIs/lookups_apis';

import { clearDataFromCache } from '../../util/lokiCache';

import styles from './ItemActions.module.scss';
import { showNotification } from '../../util/helpers';
import { checkIfRequestExistInStudyStage, generalAdmissionSettingsEditValidation } from '../../util/LookUpValidation';

const ItemActions = ({
  label, lookupType, item, handleDelete, handleEdit,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [count, setCount] = useState(0);
  const [loadingCount, setloadingCount] = useState(false);
  const [deleteLoadingCount, setdeleteLoadingCount] = useState(false);
  const servicesMapper = {
    studyStages: 'admission',
    scholarShipStudyStages: 'scholarshipAdmission',
    studyStagesPost: 'pg-admission',
    pgScholarShipStudyStages: 'pgAdmissionScholarship',
    arabicScholarshipPeriods: 'arabic_department_scholarship',
    pgservicesOpeningClosingTimes: {
      postponePGEducation: 'pg-postpone',
      transferPGEducation: 'pg-transfer',
      renominationPGEducation: 'pg-renomination',
      pgEditNomination: 'pg-edit-nomination',
    },
  };
  const checkBeforeDelete = async () => {
    if (['studyStages', 'studyStagesPost', 'pgservicesOpeningClosingTimes', 'scholarShipStudyStages', 'pgScholarShipStudyStages', 'arabicScholarshipPeriods'].includes(lookupType)) {
      const filter = { filter: { currentStage: item.value } };
      const service = lookupType === 'pgservicesOpeningClosingTimes' ? servicesMapper[lookupType][item.service] : servicesMapper[lookupType];
      try {
        setdeleteLoadingCount(true);
        const { count } = await LOOKUPS_APIS.requestsCustomCount(service, filter);
        setdeleteLoadingCount(false);
        if (count) {
          setWarningModal(true);
        } else {
          setDeleteModal(true);
        }
      } catch (e) {
        setdeleteLoadingCount(false);
        showNotification({
          type: 'error',
          message: 'حدث خطأ أثناء التنفيذ',
          description: e?.response?.data?.message,
        });
      }
    } else {
      setDeleteModal(true);
    }
  };

  const deleteHandler = async () => {
    // setDeleteModal(false);
    setSubmitting(true);
    try {
      const result = await LOOKUPS_APIS.removeLookupItem({
        name: lookupType,
        item,
      });

      if (result.data.success) {
        clearDataFromCache({ name: lookupType });
        showNotification({
          type: 'success',
          message: 'تمت العملية بنجاح',
        });

        handleDelete();
        setDeleteModal(false);
        setSubmitting(false);
      }
    } catch (e) {
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: e?.response?.data?.message,
      });
      setDeleteModal(false);
      setSubmitting(false);
    }
  };

  const editHandler = async (values) => {
    setSubmitting(true);
    const {
      arabicName, stageName, year, date: newDate, closingDate,
    } = values;
    let modifiedItem;
    if (lookupType === 'servicesOpeningClosingTimes' || lookupType === 'pgservicesOpeningClosingTimes') {
      modifiedItem = {
        label: arabicName || `${stageName}`,
        metaData: { ...values },
      };
    } else if (lookupType === 'fileWithdrawalRatiosCycle') {
      const { month = 8, day = 1 } = values;
      modifiedItem = {
        metaData: {
          ...item,
          ...values,

        },
      };
    } else {
      modifiedItem = {
        label: arabicName || `${stageName} ${year[0]}/${year[1]}`,
        metaData: { ...item, ...values },
      };
    }

    try {
      if (lookupType === 'generalAdmissionSettings') {
        const generalAdmissionValidation = [await generalAdmissionSettingsEditValidation(item)].filter(Boolean);
        if (generalAdmissionValidation.length) {
          return generalAdmissionValidation.forEach((error) => {
            setSubmitting(false);
            showNotification({
              type: 'error',
              message: 'حدث خطأ أثناء التنفيذ',
              description: error?.message || '',
            });
          });
        }
      }

      const {
        englishName, stageName: itemStage, year: itemYear, value,
      } = item;
      if (lookupType === 'pgservicesOpeningClosingTimes') {
        const startOldDate = item.date.split('-')[0].split('/').join('-').trim();
        const startOldYear = item.year.split('-')[0].trim();
        const endOldYear = item.year.split('-')[1].trim();
        const startNewDate = values.date[0];
        const startNewYear = values.year[0];
        const endNewYear = values.year[1];
        if (startOldDate !== startNewDate || startOldYear !== startNewYear || endOldYear !== endNewYear || item.service
        !== values.service) {
          const filter = { filter: { currentStage: item.value } };
          const service = servicesMapper[lookupType][item.service];
          const { count } = await LOOKUPS_APIS.requestsCustomCount(service, filter);
          if (count) {
            setSubmitting(false);
            return showNotification({
              type: 'error',
              message: 'حدث خطأ أثناء التنفيذ',
              description: 'عذرا تم تقديم طلب بالفعل فى هذة الفترة',
            });
          }
        }
        const isEndDateisAfterCurrentEndDate = moment(values?.date[1]).isAfter(moment().format('YYYY/MM/DD'));
        if (!isEndDateisAfterCurrentEndDate) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: 'تاريخ النهاية يجب ان يكون اكبر من التاريخ الحالي',
          });
        }
      }

      if (['studyStages', 'scholarShipStudyStages', 'arabicScholarshipPeriods'].includes(lookupType)) {
        const isclosingDateGreaterThanEndDate = moment(values.closingDate).isAfter(values.date[1]);
        if (!isclosingDateGreaterThanEndDate && values.closingDate) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: 'تاريخ إغلاق الطلب يجب ان يكون اكبر من تاريخ النهاية',
          });
        }
      }
      if (['pgScholarShipStudyStages', 'arabicScholarshipPeriods'].includes(lookupType)) {
        if (
          moment(newDate[0]).year() < Number(year[0])
          || moment(newDate[1]).year() > Number(year[1])
        ) {
          setSubmitting(false);
          let message = 'تاريخ البداية و النهاية يجب ان يكون خلال فترة السنة الدراسية';
          if (lookupType === 'arabicScholarshipPeriods') {
            message = 'تاريخ البداية و النهاية يجب أن يكون خلال فترة المنحة';
          }
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: message,
          });
        }
      }
      if (lookupType === 'arabicScholarshipPeriods' && count && !loadingCount) {
        if (newDate.some(date => date === 'Invalid date')) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description: 'برجاء ادخال تاريخ البداية الى تاريخ النهاية!',
          });
        }
        const [, endYear] = year;
        let [, endDate] = newDate;
        const currentDate = moment().format('YYYY-MM-DD');
        const formatedClosingDate = moment(closingDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');
        const currentYear = moment().year();
        if (!(+endYear >= currentYear)) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description:
              'العام الثاني يجب ان يكون اكبر من او يساوي العام الحالي',
          });
        }
        if (!(endDate >= currentDate)) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description:
              'تاريخ النهايه يجب ان يكون اكبر من او يساوي التاريخ الحالي',
          });
        }
        if (!(formatedClosingDate >= currentDate)) {
          setSubmitting(false);
          return showNotification({
            type: 'error',
            message: 'حدث خطأ أثناء التنفيذ',
            description:
              'تاريخ اغلاق الطلب يجب ان يكون اكبر من او يساوي التاريخ الحالي',
          });
        }
      }

      const result = await LOOKUPS_APIS.editLookupItem({
        value,
        name: lookupType,
        item: modifiedItem,
        comparisons: {
          englishName,
          stageName: itemStage,
          year: itemYear && itemYear.split(' - '),
        },
      });

      if (result.data.success) {
        clearDataFromCache({ name: lookupType });
        showNotification({ type: 'success', message: 'تمت العملية بنجاح' });
        handleEdit();
        setEditModal(false);
        setSubmitting(false);
      }
    } catch (e) {
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: e?.response?.data?.message,
      });

      setEditModal(false);
      setSubmitting(false);
    }
  };
  const openEditModel = async () => {
    try {
      if (lookupType === 'arabicScholarshipPeriods' && item) {
        setloadingCount(true);
        const filter = { filter: { currentStage: item.value } };
        const count = await checkIfRequestExistInStudyStage('arabic_department_scholarship', filter);
        setCount(count);
        setEditModal(true);
        setloadingCount(false);
      }
      setEditModal(true);
    } catch (error) {
      setloadingCount(false);
      setEditModal(false);
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: error?.message,
      });
    }
  };
  useEffect(() => {
    if (loadingCount || deleteLoadingCount) {
      const ele = document.querySelector('.ant-tabs-content-holder');
      ele.setAttribute('style', 'pointer-events: none;');
    } else {
      const ele = document.querySelector('.ant-tabs-content-holder');
      ele.setAttribute('style', 'pointer-events: auto;');
    }
  }, [loadingCount, deleteLoadingCount]);

  return (
    <div>
      {loadingCount ? <LoadingOutlined className={styles.icon} /> : <EditOutlined onClick={openEditModel} className={styles.icon} />}

      {![
        'specializations',
        'programs',
        'services',
        'feesTypes',
        'generalAdmissionSettings',
        'pgUniversityWaitingTime',
        'fileWithdrawalRatiosCycle',
      ].includes(lookupType) && (
        !(lookupType === 'arabicDepPrograms' && item.notDeletable) && (
          deleteLoadingCount ? <LoadingOutlined className={styles.icon} /> : (
            <DeleteOutlined
              onClick={() => checkBeforeDelete()}
              className={styles.icon}
            />
          )

        )
      )}
      <Modal
        forceRender
        destroyOnClose
        title={`تعديل في ${label}`}
        visible={editModal}
        onOk={editHandler}
        onCancel={() => setEditModal(false)}
        confirmLoading={submitting}
        footer={null}
      >
        {submitting ? (
          <div className={styles.spinner}>
            <Spin
              size="large"
              tip="برجاء الانتظار لحين الانتهاء من التعديل ..."
            />
          </div>
        ) : (
          <LookupAction
            count={count}
            lookupType={lookupType}
            item={item}
            editModal={editModal}
            handleSubmit={editHandler}
          />
        )}
      </Modal>

      <Modal
        title={`حذف من ${label}`}
        visible={deleteModal}
        onOk={deleteHandler}
        onCancel={() => setDeleteModal(false)}
        confirmLoading={submitting}
        footer={
          submitting ? null : (
            <Button onClick={deleteHandler} className={styles.submitDelete}>
              تأكيد
            </Button>
          )
        }
      >
        {submitting ? (
          <div className={styles.spinner}>
            <Spin
              size="large"
              tip="برجاء الانتظار لحين الانتهاء من الحذف ..."
            />
          </div>
        ) : (
          <div>هل انت متاكد من حذف هذا السجل ؟</div>
        )}
      </Modal>

      <Modal
        title={`حذف من ${label}`}
        visible={warningModal}
        onOk={() => setWarningModal(false)}
        onCancel={() => setWarningModal(false)}
        confirmLoading={submitting}
        footer={
          submitting ? null : (
            <Button onClick={() => setWarningModal(false)} className={styles.submitDelete}>
              إغلاق
            </Button>
          )
        }
      >
        {submitting ? (
          <div className={styles.spinner}>
            <Spin
              size="large"
              tip="برجاء الانتظار لحين الانتهاء من الحذف ..."
            />
          </div>
        ) : (
          <div>{lookupType === 'pgservicesOpeningClosingTimes' ? 'عذرا تم تقديم طلب بالفعل فى هذة الفترة' : lookupType === 'arabicScholarshipPeriods' ? 'لا يمكن حذف تلك الفترة لوجود طلبات بها' : 'عفواً, هناك طلبات حالية فى هذة المرحلة الدراسية'}</div>
        )}
      </Modal>
    </div>
  );
};
export default ItemActions;
