// eslint-disable-next-line import/no-cycle
import { handleLabelAndUUID } from '../helpers';

import { validateFromGradeTo } from '../validationHelpers/customValidation';

export const subjectsSchema = async ({
  specialization,
  removedOptions = [],
  subjects,
  noFullMark,
  isNotPercentage,
}) => {
  const schema = {};

  const mainOptions = [
    {
      label: 'علمي رياضة',
      value: 1,
    },
    {
      label: 'علمي علوم',
      value: 2,
    },
    {
      label: 'أدبي',
      value: 3,
    },
  ];

  const options = mainOptions.filter(
    ({ value }) => !removedOptions.includes(value),
  );

  if (specialization) {
    schema.specialization = {
      ...handleLabelAndUUID('التخصص', 'specialization'),
      uniforms: {
        options,
      },
    };
  }

  const fullMarkObj = noFullMark
    ? {}
    : {
      fullMark: {
        ...handleLabelAndUUID('الدرجة العظمى', 'fullMark', {}),
        validate: ({ value, formValues: { equation } }) => {
          if (['1', '3', '8'].includes(equation)) {
            if (isNotPercentage && (!value.match(/^\d+(\.\d{1,2})?$/) || value.length > 5 || value === '0')) {
              return 'invalidAtMost5Digits';
            }
          } else if (isNotPercentage && !value.match(/^[1-9][0-9]{0,2}$/)) {
            return 'invalidAtMost3Digits';
          }
          // eslint-disable-next-line radix
          // eslint-disable-next-line radix
          if (!isNotPercentage && (!value.match(/^[1-9][0-9.]*$/) || parseInt(value) > 100)) {
            return 'invalidPercentage';
          }
        },
      },
    };

  return {
    ...schema,
    subjectName: {
      ...handleLabelAndUUID('اسم المادة', 'subjectName'),
      uniforms: {
        options: subjects,
      },
    },
    ...fullMarkObj,
  };
};

export const factorPercentageSchema = () => ({
  year: {
    ...handleLabelAndUUID('سنة الشهادة', 'Degree Year'),
    validate: ({ value }) => {
      if (!value.match(/^(19[5-9]\d|20[0-4]\d|2050)$/)) return 'invalidYear';
    },
  },
  percentage: {
    ...handleLabelAndUUID('النسبة المئوية مقابل المعامل', 'Factor percentage'),
    validate: validateFromGradeTo,
  },
});
