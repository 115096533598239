import * as appDataActions from './appData.actions';
import * as lookupsDataActions from './lookUpsData.actions';
import * as userDataActions from './userData.actions';

const actions = {
  ...lookupsDataActions,
  ...userDataActions,
};

export {
  actions,
};
