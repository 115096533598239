import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import moment from 'moment';
import Fields from './Fields';
import CustomButton from '../UIElements/Button';

const CustomForm = ({
  schema, lookupType, handleSubmit, item, count, editModal,
}) => {
  const [form] = Form.useForm();
  if (['scholarShipStudyStages', 'studyStages'].includes(lookupType) && item) {
    const isCurrentDateGreaterThanEndDate = moment(moment().format('YYYY/MM/DD')).isAfter(item?.date?.split('-')[1]);
    schema.closingDate.editable = !isCurrentDateGreaterThanEndDate;
  }
  if (lookupType === 'arabicScholarshipPeriods' && item) {
    if (count) {
      schema.stageName.editable = false;
      schema.year.editableStartYear = false;
      schema.date.editableStartYear = false;
    }
  }
  const onError = (errorInfo) => {
  };

  const manipulateValues = (values = {}) => {
    const { year, date, closingDate } = values;
    return {
      ...values,
      year: year && year.split(' - ').map(i => moment(i)),
      date: date && date.split(' - ').map(i => moment(i)),
      closingDate: closingDate && moment(closingDate),
    };
  };

  const preSubmitWrapper = (values) => {
    const {
      year, date, areScintificGradesAvailable, closingDate, type,
    } = values;
    const modifiedValues = {
      ...values,
      areScintificGradesAvailable: areScintificGradesAvailable || 'no',
      type: type || 'faculty',
      year: year && year.map(i => moment(i).format('YYYY')),
      date: date && date.map(i => moment(i).format('YYYY-MM-DD')),
      closingDate: closingDate && moment(closingDate).format('YYYY/MM/DD'),
    };
    handleSubmit(modifiedValues);
  };
  useEffect(() => {
    form.setFieldsValue(manipulateValues(item));
  }, [form, item, editModal]);

  return (
    <Form
      form={form}
      resetFields
      layout="vertical"
      name="basic"
      initialValues={item && manipulateValues(item)}
      onFinish={preSubmitWrapper}
      onFinishFailed={onError}
    >
      {Object.entries(schema)
        .map(([key, schemaItem]) => Fields({ key, schemaItem, item }))}
      <Form.Item>
        <CustomButton type="submit">
          {' '}
          {item ? 'حفظ' : 'اضافة'}
        </CustomButton>

      </Form.Item>
    </Form>
  );
};
export default CustomForm;
