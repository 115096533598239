import React from 'react';
import Form from 'antd/lib/form';
import DatePicker from 'antd/lib/date-picker';
import RangeDateField from '../RangeDateField/RangeDateField';
import { FormField } from '../FormFields/FormFieldsHelpers';

const { RangePicker } = DatePicker;

const RangeDate = props => (
  <FormField
    {...props}
    render={({ input, error }) => (
      <RangeDateField
        error={error}
        dates={props.inputProps.allowedValues}
        input={input}
        {...props}
      />
    )}
  />
);

export default RangeDate;
