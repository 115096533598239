import React from 'react';
import Tabs from 'antd/lib/tabs';
import ListItems from '../../Containers/ListItems/ListItems';
import styles from './tabs.module.scss';

const { TabPane } = Tabs;

const CommonTabs = (props) => {
  const { tabs, history } = props;
  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey={tabs[0].value}
        type="card"
        className={styles.main}
        {...props}
      >
        {tabs.map(({ label, key }) => (
          <TabPane tab={label} key={key}>
            <ListItems label={label} accessor={key} history={history} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default CommonTabs;
