import { actions } from '../actions';
import actionStatuses from '../actions/actionStatuses';

const initalState = {
  userData: {
    roles: [],
  },
  accessToken: '',
  loading: false,
  model: {},
  error: '',
  authMeta: {},
};

const userReducer = (state = initalState, {
  type,
  data,
  token,
  model,
  status,
  error,
}) => {
  switch (type) {
  case actions.SET_MODEL_DATA: {
    // if (status === actionStatuses.START) return { ...state, loading: true };
    // if (status === actionStatuses.SUCCESS) {
    //   return {
    //     ...state,
    //     model,
    //     status,
    //     loading: false,
    //   };
    // }
    // if (status === actionStatuses.FAILED) {
    //   return {
    //     ...state,
    //     status,
    //     loading: false,
    //     error,
    //   };
    // }
    return {
      ...state,
      status,
      model,
      loading: false,
      error,
    };
    // break;
  }
  case actions.SET_AUTH_META:
    return ({
      ...state,
      authMeta: data,
    });

    // case actions.SET_USER_DATA: {
    //   if (status === actionStatuses.START) return { ...state };
    //   if (status === actionStatuses.SUCCESS) {
    //     return ({
    //       ...state,
    //       status,
    //       userData: data,
    //       // loading: false,
    //     });
    //   }
    //   if (status === actionStatuses.FAILED) {
    //     return {
    //       ...state, status, error,
    //     };
    //   }
    //   break;
    // }
  case actions.SET_USER_DATA: {
    return ({
      ...state,
      loading: true,
      error,
      userData: data,
    });
  }
  case actions.SET_ACCESS_TOKEN:
    return ({
      ...state,
      accessToken: token,
    });
  default:
    return state;
  }
};
export default userReducer;
