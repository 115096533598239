const en = {
  translation: {
    title: 'Study in Egypt',
    appSummary: 'Study in Egypt! The new educational adventure that makes the whole difference - Your #1 to go place for education and fun. We provide you the easy access to all higher education entities in Egypt as well as facilitating your boarding through comprehensive set of services. Stay Connected and get your Educational Offers NOW!',
    auth: {
      registerTitle: 'Register',
      register: 'Register',
      loginTitle: 'Sign In',
      login: 'Sign In',
      logout: 'Logout',
      loginError: 'Email or password is incorrect',
      registrationError: 'Something went wrong!',

    },

  },
};

export default en;
