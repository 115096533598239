import axios from '../util/axios-instance';

const LISTS_APIS = name => ({
  getAll: (filters, additionalOptions) => axios.get(`/api/${name}`, { params: { filter: { where: filters }, ...additionalOptions } }).then(response => response.data),
  add: (data, additionalOptions) => axios.post(`/api/${name}`, data, { ...additionalOptions }).then(response => response.data),
  edit: (id, data, additionalOptions) => axios.patch(`/api/${name}/${id}`, data, { ...additionalOptions }).then(response => response.data),
  delete: (id, additionalOptions) => axios.delete(`/api/${name}/${id}`, { ...additionalOptions }).then(response => response.data),
});
export const reportApi = name => ({
  candidacyLimitReport: params => axios.get(`/${name}`, { params: { candidancyListName: 'condidancyLimitList', studyStagesListName: 'studyStages', filter: { ...params } } }).then(response => response.data),
});

export default LISTS_APIS;
