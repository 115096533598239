import { axios } from '../../util';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_SPECIALIZATIONS = 'SET_SPECIALIZATIONS';
export const SET_SUBJECTS = 'SET_SUBJECTS';

export const setCountries = (payload) => ({
  type: SET_COUNTRIES,
  payload,
});

export const setSpecializations = (payload) => ({
  type: SET_COUNTRIES,
  payload,
});

export const setSubjects = (payload) => ({
  type: SET_COUNTRIES,
  payload,
});

// export const fetchAppData = () => (dispatch) => {
//   axios
//     .get('')
//     .then((response) => response.data)
//     .then((data) => dispatch(setAppData(data)));
// };
