import React from 'react';
import styles from './button.module.scss';

const CustomButton = ({
  text, secondary, fullWidth, midWidth, action, type, disabled, children, isGroupElement, marginInline, className = '',
}) => {
  const styling = `${styles['custom-button']} 
  ${secondary ? styles['secondary-button'] : ''}
  ${fullWidth ? styles['fullwidth-button'] : ''}
  ${midWidth ? styles['midwidth-button'] : ''}
  ${marginInline ? styles['margin-inline'] : ''}
  ${isGroupElement ? styles['custom-button-group-element'] : ''}
  `;
  switch (type) {
  case 'submit':
    return (
      <button
        type="submit"
        onClick={action ? () => action() : null}
        className={`${styling} ${className}`}
        disabled={disabled}
      >
        {children}
      </button>

    );
  case 'button':
    return (
      <button
        type="button"
        onClick={action ? () => action() : null}
        className={`${styling} ${className}`}
        disabled={disabled}
      >
        {children}
      </button>

    );
  default:
    return null;
  }
};

export default CustomButton;
