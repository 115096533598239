import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import moment from 'moment';
import LookupAction from '../LookupAction/LookupAction';
import TableLists from '../TableLists/TableLists';
import CustomButton from '../../Components/UIElements/Button';

import LOOKUPS_APIS from '../../APIs/lookups_apis';

import styles from './ListItems.module.scss';

import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { showNotification } from '../../util/helpers';

import { clearDataFromCache } from '../../util/lokiCache';
import { getLookupOptions } from '../../FormSchemas/form-dynamic/helpers';

const ListItems = ({ label, accessor, history }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [isModified, setIsModified] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const manipulateWrapperFn = (item) => {
    const { metaData, value } = item;

    if (['studyStages', 'studyStagesPost', 'ugTransferAbroadStudyStages', 'scholarShipStudyStages', 'pgScholarShipStudyStages', 'arabicScholarshipPeriods'].includes(accessor)) {
      const { year, date } = metaData;
      return {
        value,
        ...metaData,
        year: year && `${moment(year[0]).year()} - ${moment(year[1]).year()}`,
        date:
          date
          && `${moment(date[0]).format('YYYY/MM/DD')} - ${moment(date[1]).format(
            'YYYY/MM/DD',
          )}`,
      };
    }
    if (accessor === 'servicesOpeningClosingTimes' || accessor === 'pgservicesOpeningClosingTimes') {
      const { date, year } = metaData;
      return {
        value,
        ...metaData,
        year: year && `${moment(year[0]).year()} - ${moment(year[1]).year()}`,
        date:
          date
          && `${moment(date[0]).format('YYYY/MM/DD')} - ${moment(date[1]).format(
            'YYYY/MM/DD',
          )}`,
      };
    }
    return { value, ...metaData };
  };

  const getData = async () => {
    setLoading(true);
    try {
      const options = await getLookupOptions({ name: accessor, isRaw: true });
      setData(options.map(item => manipulateWrapperFn(item)));
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (isModified) {
      getData();
      setIsModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessor, isModified]);

  const handleDelete = async () => {
    setIsModified(true);
  };

  const handleEdit = async () => {
    setIsModified(true);
  };
  const handleOk = async (values) => {
    setSubmitting(true);
    const {
      arabicName, stageName, year, date, service, closingDate,
    } = values;
    if (['studyStages', 'scholarShipStudyStages', 'arabicScholarshipPeriods'].includes(accessor)) {
      const isclosingDateGreaterThanEndDate = moment(closingDate).isAfter(date[1]);
      if (!isclosingDateGreaterThanEndDate && closingDate) {
        setSubmitting(false);
        return showNotification({
          type: 'error',
          message: 'حدث خطأ أثناء التنفيذ',
          description: 'تاريخ إغلاق الطلب يجب ان يكون اكبر من تاريخ النهاية',
        });
      }
    }

    if (['studyStages', 'scholarShipStudyStages', 'ugTransferAbroadStudyStages', 'arabicScholarshipPeriods'].includes(accessor)) {
      if (
        moment(date[0]).year() < Number(year[0])
        || moment(date[1]).year() > Number(year[1])
      ) {
        setSubmitting(false);
        let message = 'تاريخ البداية و النهاية يجب ان يكون خلال فترة السنة الدراسية';
        if (accessor === 'arabicScholarshipPeriods') {
          message = 'تاريخ البداية و النهاية يجب أن يكون خلال فترة المنحة';
        }

        return showNotification({
          type: 'error',
          message: 'حدث خطأ أثناء التنفيذ',
          description: message,
        });
      }
    }
    if (['studyStagesPost', 'pgScholarShipStudyStages'].includes(accessor)) {
      if (
        moment(date[0]).year() < Number(year[0])
        || moment(date[1]).year() > Number(year[1])
      ) {
        setSubmitting(false);
        return showNotification({
          type: 'error',
          message: 'حدث خطأ أثناء التنفيذ',
          description:
            'تاريخ البداية و النهاية يجب ان يكون خلال فترة السنة الدراسية',
        });
      }
    }
    let item;
    if (accessor === 'servicesOpeningClosingTimes' || accessor === 'pgservicesOpeningClosingTimes') {
      item = {
        label: `${stageName} ${service}`,
        metaData: { ...values },
      };
    } else {
      item = {
        label: arabicName || `${stageName} ${year[0]}/${year[1]}`,
        metaData: { ...values },
      };
    }
    try {
      const result = await LOOKUPS_APIS.addLookupItem({
        name: accessor,
        item,
      });
      if (result.data.success) {
        clearDataFromCache({ name: accessor });
        showNotification({
          type: 'success',
          message: 'تمت العملية بنجاح',
        });
        setIsModified(true);
        setIsVisible(false);
        setSubmitting(false);
      }
    } catch (e) {
      showNotification({
        type: 'error',
        message: 'حدث خطأ أثناء التنفيذ',
        description: e?.response?.data?.message,
      });
      setSubmitting(false);
    }
  };

  const Header = ({ title }) => (
    <div className={styles.header}>
      <div className={styles['page-header-container']}>
        <div className="title-container">
          <h2 className="tableHeadTitle">{`قائمة ${title}`}</h2>
        </div>

        {['countries', 'pgUniversityWaitingTime', 'fileWithdrawalRatiosCycle'].includes(accessor) ? null : (
          <small className={styles['header-buttons']}>
            <CustomButton type="button" action={() => setIsVisible(true)}>
              اضافة
            </CustomButton>
          </small>
        )}
      </div>
    </div>
  );
  return (
    <div>
      {error ? (
        <ErrorMessage history={history} />
      ) : (
        <div>
          <Header title={label} />

          <div className={styles.table}>
            <TableLists
              loading={loading}
              lookupType={accessor}
              data={data}
              label={label}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              pagination={!!data.length}
            />
          </div>

          <Modal
            title={`اضافة الى ${label}`}
            visible={isVisible}
            onOk={handleOk}
            onCancel={() => setIsVisible(false)}
            footer={null}
          >
            <LookupAction
              lookupType={accessor}
              handleSubmit={handleOk}
              loading={submitting}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ListItems;
