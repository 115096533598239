import axios from '../util/axios-instance';

const SETTINGS_APIS = name => ({
  getAll: () => axios.get(`/${name}`).then(response => response.data),
  add: data => axios.post(`/${name}`, data).then(response => response.data),
  edit: data => axios.patch(`/${name}`, data).then(response => response.data),
  delete: ({ value }) => axios.delete(`/${name}/${value}`).then(response => response.data),
});

export default SETTINGS_APIS;
