/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
} from 'react';
import { Form } from 'react-final-form';
import Modal from 'antd/lib/modal/Modal';
import styles from './MultipleFields.module.scss';
import FormFields from '../FormFields';
import CustomButton from '../../UIElements/Button';

const EditAction = ({ initialValues, schema, onEdit }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <span>
      <Modal
        forceRender
        destroyOnClose
        title="تعديل"
        open={showEditModal}
        onCancel={() => {
          setShowEditModal(false);
        }}
        footer={null}
        confirmLoading
      >
        <Form
          onSubmit={(data) => {
            onEdit(data);
            setShowEditModal(false);
          }}
          initialValues={initialValues}
          render={({ handleSubmit, values, form: { change } }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
            >
              <FormFields
                data={{ fieldsSchema: schema }}
                formValues={values}
                changeFieldValue={change}
              />
              <div>
                <CustomButton type="submit" secondary midWidth>
                  <div>تعديل</div>
                </CustomButton>
              </div>
            </form>
          )}
        />
      </Modal>
      <button
        type="button"
        className={styles['del-button']}
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        <i className="fa fa-edit" />
      </button>
    </span>
  );
};

export default EditAction;
