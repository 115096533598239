import axios from '../util/axios-instance';

const LOOKUPS_APIS = {

  getOptions: ({ name }) => axios.get(`/lookups/getOptions?name=${name}`).then(response => response.data),

  getItemByValue: ({ name, category, value }) => axios.get(`/lookups/getItemByValue?name=${name}&value=${value}${category ? `&category=${category}` : '&'}`).then(response => response.data),

  addLookupItem: data => axios.post('/lookups/addItem', data).then(response => response.data),

  removeLookupItem: data => axios.post('/lookups/removeItem', data).then(response => response.data),

  editLookupItem: data => axios.post('/lookups/editItem', data).then(response => response.data),

  requestsCustomCount: (service, filter) => axios.get('/requestsCustomCount', { params: { service, filter } }).then(({ data }) => data),
};

export default LOOKUPS_APIS;
