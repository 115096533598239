import React, { useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import DatePicker from 'antd/lib/date-picker';
import styles from './RangeDateField.module.scss';

const { RangePicker } = DatePicker;
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

export default ({
  dates,
  input,
  inputProps,
  error,
  formValues,
}) => {
  const { onChange, name, onBlur } = input;
  const {
    optional, hidden, label, hasStart,
  } = inputProps;
  const [initialDate, setInitialDate] = useState('');
  const disabledDate = (current) => {
    if (!hasStart) return null;
    return current && current < moment().startOf('day');
  };

  const defaultDateValue = () => {
    const yearsRange = get(formValues, `${name}`)?.split(' - ');
    return yearsRange ? [moment(yearsRange[0]), moment(yearsRange[1])] : initialDate;
  };

  moment.locale('ar-EG');

  return (
    <>
      <label
        className={`${!optional ? styles['field-required'] : ''} ${
          hidden ? styles['label-hidden'] : ''
        }`}
      >
        {label}
      </label>
      <RangePicker
        disabledDate={disabledDate}
        defaultValue={defaultDateValue}
        className={`${error ? 'date-field_hasError' : ''}`}
        size="large"
        placeholder={['من', 'الى']}
        onBlur={onBlur}
        onChange={(year) => {
          if (!year) return onChange(undefined);
          setInitialDate(year);
          onChange(`${year[0].year()} - ${year[1].year()}`);
        }}
        picker="year"
        format="YYYY"
      />
    </>
  );
};
