import Countly from 'countly-sdk-web';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

Countly.init({
  url: window.env.REACT_APP_COUNTLY_URL,
  app_key: window.env.REACT_APP_COUNTLY_API_KEY,
});
Countly.begin_session();

window.addEventListener('click', (e) => {
  if (e.target.nodeName !== 'BUTTON') return;
  const {
    type, textContent, className, value,
  } = e.target;
  Countly.q.push(['add_event', {
    key: 'buttonClick',
    segmentation: {
      type,
      textContent,
      className,
      value,
    },
  }]);
});

Countly.q.push(['track_sessions']);
Countly.q.push(['track_links']);
Countly.q.push(['track_forms', null, true]);
Countly.q.push(['track_errors']);

export default (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    Countly.q.push(['track_pageview']);
  }, [pathname]);
  return null;
};
