import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import TextInput from '../Types/TextInput';
import TextArea from '../Types/TextArea';
import NumberInput from '../Types/NumberInput';
import FlexibleMulti from '../Types/FlexibleMulti';
import Select from '../Types/Select';
import Parent from '../Types/Parent';
import RequestDate from '../Types/RequestDate';
import DependentOnLazySelect from '../Types/DependentOnLazySelect';
import Radio from '../Types/Radio';
import RangeDate from '../Types/RangeDate';
import FileUpload from '../Types/FileUpload';

export const typeGetter = (node, rawSchema, hasChildren) => {
  const {
    uniforms: { type, options, checkboxes: radio } = {}, name, allowedValues, type: rootType, schema, handler, dependentOn,
    lazyLoad,
  } = node;
  if (hasChildren) return Parent;
  if (name.includes('requestDate') || rootType === 'Date') return RequestDate;
  if (type === 'rangeDate') return RangeDate;
  // if (options && (_get(rawSchema, `${name}#$`) || _get(rawSchema, `${name}.$`))) return CheckBox;
  // if (radio) return Radio;
  if (options || allowedValues) {
    if (!dependentOn && !lazyLoad) {
      return Select;
    }

    if (dependentOn) {
      return DependentOnLazySelect;
    }
  } // if (type === 'price') return Price;
  if (type === 'radio') return Radio;
  if (options || allowedValues) return Select;
  // if (type === 'price') return Price;
  if (rootType === 'Number') return NumberInput;
  if (!_isEmpty(schema)) return FlexibleMulti;
  // if (!_isEmpty(schema)) return FlexableTable;
  if (type === 'textarea') return TextArea;
  if (type === 'fileUpload') return FileUpload;
  return TextInput;
};
