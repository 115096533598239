import {
  SET_COUNTRIES,
  SET_SUBJECTS,
  SET_SPECIALIZATIONS,
} from '../actions/lookUpsData.actions';

const initalState = {
  countries: [],
  specializations: [],
  certificates: [],
  subjects: [],
};

export default function lookUpsDataReducer(
  state = initalState,
  {
    type, data, token, model, status, error, userStatus,
  },
) {
  switch (type) {
  case SET_COUNTRIES:
    return { ...state, ...data };
  default:
    return state;
  }
}
