import React from 'react';
import _get from 'lodash/get';
import Radio from 'antd/lib/radio';
import { FormField } from '../FormFields/FormFieldsHelpers';
import styles from './FormFields.module.scss';

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
const RadioField = (props) => {
  const {
    inputProps: {
      optional,
      label,
      hidden,
      uniforms: { options },
    },
    formProps,
  } = props;
  return (
    <FormField
      {...props}
      // type="radio"
      display="block"
      render={({ input }) => {
        const { onChange } = input;
        return (
          <>
            <label
              className={`${!optional ? styles['field-required'] : ''} ${
                                hidden ? styles['label-hidden'] : ''
                                }`}
            >
              {label}
            </label>
            {' '}
            {options.map(({ label, value }) => (
              <Radio
                {...input}
                checked={_get(props.formValues, input.name) === value}
                onChange={(e) => {
                  onChange(value);
                }}
                color="primary"
              >
                <span>{label}</span>
              </Radio>
            ))}
          </>
        );
      }}
    />
  );
};

export default RadioField;
