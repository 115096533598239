import React from 'react';
// import styles from './FormFields.module.scss';
import useElementsTree from './useElementsTree';
import { typeGetter } from './typesConfig';
import styles from './FormInput/FormInput.module.scss';

const FormFields = ({ data: { fieldsSchema: rawSchema }, formValues, ...formProps }) => {
  const elements = useElementsTree({
    typeGetter, rawSchema, otherProps: { formValues, formProps },
  });
  return <section className={styles['input-group']}>{elements}</section>;
};
export default FormFields;
