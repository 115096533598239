import _flattenDeep from 'lodash/flattenDeep';

import { getDataFromCache, setDataToCache } from '../../util/lokiCache';

import LOOKUPS_APIS from '../../APIs/lookups_apis';

// eslint-disable-next-line import/no-cycle
import { subjectsSchema } from './additional/customSchema';

export const defaultProperties = (additional = {}) => ({
  optional: false,
  internal: false,
  showField: false,
  editable: true,
  hidden: false,
  visible: true,
  type: 'String',
  uniforms: {},
  // customRegex: {
  //   'error from regex': ['abc', 'i']
  // },
  ...additional,
});

export const handleLabelAndUUID = (label, uuid, additional) => ({
  label,
  uuid,
  ...defaultProperties(additional),
});

export const defaultFlexibleProps = (
  groupBy,
  unique = [],
  additional = {},
  renderOptions = {},
  alike = [],
  minEntries = '1',
) => ({
  render: {
    type: 'hTable',
    groupBy,
    unique,
    alike,
    minEntries,
    actions: ['delete', 'edit', 'add'],
    ...renderOptions,
  },
  remoteValidation: true,
  type: 'Object',
  ...additional,
});

export const defaultFlexiblePropsV2 = ({
  groupBy = null,
  unique = [],
  additional = {},
  renderOptions = {},
  alike = [],
  minEntries = '1',
}) => ({
  render: {
    type: 'hTable',
    groupBy,
    unique,
    alike,
    minEntries,
    actions: ['delete', 'edit', 'add'],
    ...renderOptions,
  },
  remoteValidation: true,
  type: 'Object',
  ...additional,
});

export const handleConditional = (
  equationsNumbers,
  field,
  behavior = 'show',
  operator = 'or',
) => ({
  conditionalField: {
    behavior,
    operator,
    conditions: equationsNumbers.map(num => ({
      field: field || 'equation',
      relation: 'is',
      value: num,
      fieldName: field || 'equation',
    })),
  },
});

export const handleConditionalMultiple = (
  conditions, // [{ field: 'equation', relation: 'contains', value: ['25']}]
  behavior = 'show',
  operator = 'or',
) => ({
  conditionalField: {
    behavior,
    operator,
    conditions: conditions.map(({ field, relation = 'is', value }) => ({
      field,
      relation,
      value,
      fieldName: field,
    })),
  },
});

export const handleNumberMaxAndMin = (min, max, numberType) => ({
  min: min && `${min}`,
  max: max && `${max}`,
  type: 'Number',
  numberType: numberType || 'integer',
  uniforms: {
    type: 'Number',
  },
});

export const handleGroup8 = async (groupName, subjects) => ({
  [`${groupName}.optionalMax`]: {
    ...handleLabelAndUUID('عدد اعلى مواد اختيارية', 'optionalMax'),
    ...handleConditional(['8']),
    ...handleNumberMaxAndMin(1, 10),
  },

  [`${groupName}.subjects`]: {
    ...handleLabelAndUUID('المواد الاساسية', 'subjects'),
    ...handleConditional(['8']),
    schema: await subjectsSchema({ subjects, isNotPercentage: true }),
    ...defaultFlexiblePropsV2({ unique: ['subjectName'], minEntries: '1' }),
  },

  [`${groupName}.optionalSubjects`]: {
    ...handleLabelAndUUID('المواد الاختيارية', 'optionalSubjects'),
    ...handleConditional(['8']),
    schema: await subjectsSchema({ subjects, isNotPercentage: true }),
    ...defaultFlexiblePropsV2({ unique: ['subjectName'], minEntries: '1' }),
  },
});

const getLookupOptionsInitial = async (
  {
    name, isRaw, isEquation, filterFn, mapFn,
  },
) => {
  const { options } = (
    await LOOKUPS_APIS.getOptions({
      name,
    })
  ).data;

  if (isRaw) return options;

  if (filterFn) {
    return options.filter(filterFn).map(option => ({
      label: option.metaData.arabicName,
      value: option.value,
    }));
  }
  if (mapFn) {
    return options.map(mapFn);
  }

  return options.filter(({ value }) => ![18].includes(Number(value))).map(option => ({
    ...option,
    label: isEquation ? option.label : option.metaData.arabicName,
    value: option.value,
  }));
};

export const getLookupOptions = async ({ name, isRaw, ...args }) => {
  const cachedData = getDataFromCache({ name, isRaw });

  const options = cachedData || await getLookupOptionsInitial({ name, isRaw, ...args });

  if (!cachedData) setDataToCache({ name, isRaw, options });

  return options;
};
