import { useMemo, createElement } from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';

// monads
const Right = x => ({
  map: f => Right(f(x)),
  chain: f => f(x),
  fold: (f, g) => g(x),
});

const Left = x => ({
  map: f => Left(x),
  chain: f => Left(x),
  fold: (f, g) => f(x),
});

// monoids
const Node = (x, concatable) => ({
  x,
  concatable,
  extract: () => x,
  concat: (otherNode) => {
    if (concatable && otherNode.concatable) {
      const pathOldValue = _get(x, otherNode.x.__path);
      return Node(_set({ ...x }, otherNode.x.__path, { ...pathOldValue, ...otherNode.x }), true);
    }
    if (concatable) return Node(x, true);
    return otherNode;
  },
});
Node.empty = () => Node({}, true);

// functors
const List = xs => ({
  foldMap: (f, empty) => xs.reduce((base, point) => base.concat(f(point)), empty),
  extract: () => xs,
});

// utils
const fromNullable = (x, msg) => (x == null ? Left(msg) : Right(x));
const replaceAll = (str, what, With) => str.split(what).join(With);
const throwError = (x) => { throw new Error(x); };
const rootsToElementsTree = ({ typeGetter, rawSchema, ...rest }) => function mapper(node) {
  return Object.values(node)
    .map(({ __children, __path, ...inputProps }) => (
      {
        type: typeGetter(inputProps, rawSchema, __children),
        props: {
          typeGetter, inputProps, rawSchema, ...rest,
        },
        ...(__children ? { children: mapper(__children) } : {}),
      }
    ));
};
const elementsTreeToReactTree = restProps => function mapper(xs) { return xs.map(({ type, props, children }) => createElement(type, { ...props, ...restProps }, ...mapper(children || []))); };

// app
const useElementsTree = ({
  typeGetter, rawSchema, otherProps,
}) => {
  const elementsTree = useMemo(() => fromNullable(typeGetter, 'type getter is missing')
    .chain(f => fromNullable(rawSchema, 'schema is missing'))
    .map(Object.entries)
    .map(xs => List(xs)
      .foldMap(([key, value]) => Node({ ...value, name: key, __path: replaceAll(key, '.', '.__children.') }, !key.endsWith('$')), Node.empty())
      .extract())
    .map(rootsToElementsTree({
      typeGetter, rawSchema,
    })), [rawSchema, typeGetter]);
  return useMemo(() => elementsTree.fold(throwError, elementsTreeToReactTree(otherProps)), [elementsTree, otherProps]);
};

export default useElementsTree;
