import React, {
  useState, useEffect, useMemo, useRef, lazy,
} from 'react';
import _get from 'lodash/get';
import _concat from 'lodash/concat';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';
// import styles from '../FormFields.module.scss';
import axios from '../../util/axios-instance';
import { FormField } from '../FormFields/FormFieldsHelpers';
import { formatErrorText, getDataFromApi, getDependecies } from '../../util/helpers';
import InputStyles from './FormFields.module.scss';
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

export default function DependentOnLazySelect(props) {
  const {
    inputProps, formProps, rawSchema, formValues, parentSchema,
  } = props;
  const {
    editable,
    lazyLoad,
    uuid,
    name,
    hidden,
    optional,
    dependentOn,
    isParentDependent,
    label,

  } = inputProps;
  const {
    // cacheLazyLoadableSelectOptions,
    // getCachedValue,
    slug,
    changeFieldValue,
  } = formProps;
  const [selectOptions, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const deps = getDependecies(name, rawSchema);

  const parentKey = Object.keys(isParentDependent ? parentSchema : rawSchema).find(key => key.includes(dependentOn));

  const parentValue = _get(formValues, parentKey);

  useEffect(() => {
    if (!parentValue) return;
    const { url, apiDependencies } = lazyLoad;
    setLoading(true);
    getDataFromApi({
      slug, apiDependencies, formValues, name, url,
    })
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setLoading(false);
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [parentValue]);

  return (
    <FormField
      {...props}
      noWrapper
      // noErrorHandling
      render={({ input, error }) => {
        const {
          onChange, value: fmValue, ...restInputProps
        } = input;
        const selectValue = selectOptions.find(
          ({ value }) => value === fmValue,
        );

        const isSelectDisabled = !editable || !selectOptions.length || loading;

        return (
          <>
            {/* <div className={`${styles['field-container']} ${styles['inline-field']}`}>
              <WithLabel name={name} wrapperClass={styles.customSelectWrapper} label={label}> */}
            <label
              className={`${!optional ? InputStyles['field-required'] : ''} ${
                hidden ? InputStyles['label-hidden'] : ''
              }`}
            >
              {label}
            </label>
            <ReactSelect
              isRtl
              isSearchable
              isClearable
              isDisabled={isSelectDisabled}
              options={selectOptions}
              noOptionsMessage={() => 'لا توجد بيانات'}
              className={`${styles['custom-select']} ${
                error && styles.hasError
              }`}
              {...restInputProps}
              classNamePrefix="react-select"
              onChange={(target) => {
                onChange(_get(target, 'value', null));
                deps.forEach((dep) => {
                  changeFieldValue(dep, undefined);
                });
              }}
              value={selectValue || null}
            />
          </>
        );
      }}
    />
  );
}
