import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash/fp/isEmpty';
import { actions } from '../../redux/actions';
import { showNotification } from '../../util/helpers';

import FormFields from '../../Components/FormFields/FormFields';
import styles from './login.module.scss';
import Spinner from '../../Components/UIElements/Spinner';

const Login = ({
  userModel,
  onLogin,
  history,
  error,
  getAuthMeta,
}) => {
  const schema = {};
  const userSlug = 'configurator';
  const loginModel = {
    username: {
      defaultValue: 'admin',
      hidden: false,
      internal: false,
      label: 'اسم المستخدم',
      optional: false,
      regExp: 'textWithoutSpaces',
      showField: true,
      type: 'String',
      uniforms: { placeholder: 'اسم المستخدم', type: 'text' },
      uuid: 'username',
      visible: true,
      editable: true,
    },
    password: {
      defaultValue: 'cdkwheflkwhrERSE535646',
      hidden: false,
      internal: false,
      label: 'كلمة المرور',
      optional: false,
      regExp: 'password',
      showField: true,
      type: 'password',
      uniforms: { placeholder: 'كلمة المرور / Enter your password', type: 'password' },
      uuid: 'password',
      visible: true,
      editable: true,
    },
  };
  delete loginModel.password.regExp;
  const [formLoading, setFormLoading] = useState(false);
  const handleSubmit = (userSlug, values) => {
    setFormLoading(true);
    return onLogin(userSlug, values)
      .then(() => {
        showNotification({
          type: 'success',
          message: 'تم تسجيل الدخول بنجاح',
        });
      })
      .catch(() => {
        setFormLoading(false);
        return { [FORM_ERROR]: 'login failed' };
      });
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userModel.IAM) {
      return getAuthMeta(userModel.userSlug).then((res) => {
        window.location.href = res.url;
      });
    }
    setLoading(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  if (loading) return <Spinner type="fullscreen" />;
  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['login-card']}>
        <div className={styles['card-header']}>
          <div className={styles['card-title']}>
            {'تسجيل الدخول'}
            {' '}
          </div>
          <div className={styles['card-divider']} />
        </div>
        <div className={styles['card-body']}>
          <Form
            onSubmit={values => handleSubmit(userSlug, values)}
            render={({
              handleSubmit,
              dirtyFieldsSinceLastSubmit,
              hasValidationErrors,
              submitting,
              submitError,
              ...rest
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormFields
                  data={{ fieldsSchema: { ...loginModel } }}
                />
                {submitError && isEmpty(dirtyFieldsSinceLastSubmit) && !hasValidationErrors && !submitting
                  && (
                    <div className={styles['error-container']}>
                      اسم المستخدم او كلمه المرور غير صحيح
                    </div>
                  )}
                <button className={styles['submit-btn']} type="submit"> تسجيل الدخول </button>
              </form>
            )}
          />
        </div>

      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  error: state.userReducer.error,
  userModel: state.userReducer.model,
});
const mapDispatchToProps = dispatch => ({
  onLogin: (slug, userData) => dispatch(actions.login(slug, userData)),
  getAuthMeta: slug => dispatch(actions.getAuthMeta(slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
