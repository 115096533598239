import React, { useEffect, useState } from 'react';
import {
  Route, Switch, Redirect, withRouter, useLocation, useHistory,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ConfigProvider from 'antd/lib/config-provider';
import { connect, useSelector } from 'react-redux';
import arLocale from 'antd/es/locale/ar_EG';
import { isEmpty } from 'lodash';
import Countly from './CountlyInit';
import Layout from './Components/Layout/Layout';
import { actions } from './redux/actions';
// import Spinner from './Components/Spinner/Spinner';
// Spinner
import Login from './Containers/Login/Login';

import styles from './App.module.scss';
import './App.css';
import ErrorFallBack from './Components/ErrorFallback/ErrorFallback';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import Spinner from './Components/UIElements/Spinner';

function App({
  setAccessToken, getUserModel, getAuthMeta, exchangeToken, onGetUserData,
}) {
  const [loading, setLoading] = useState(true);
  const AppDirection = 'rtl';
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const userSlug = localStorage.getItem('userSlug');
  const tokenStorage = localStorage.getItem('accessToken');

  useEffect(() => {
    const prepareData = async () => {
      if (code && userSlug) {
        await exchangeToken(userSlug, code);
      }
      await onGetUserData();
      if (tokenStorage) {
        await setAccessToken(tokenStorage);
      }
      await getUserModel()
        .then((model) => {
          if (model.IAM) {
            return getAuthMeta(model.userSlug).then((res) => {
              if (!tokenStorage) window.location.href = res.url;
            });
          }
          return history.push('/login');
        });
      setLoading(false);
    };
    prepareData();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (loading) return <Spinner type="fullscreen" />;
  return (
    <ConfigProvider direction={AppDirection} locale={arLocale}>
      <div className={styles['main-container']}>
        <div className={styles['container-rtl']}>
          <Sentry.ErrorBoundary fallback={ErrorFallBack}>
            <Countly />
            <div className={styles['route-container']}>
              <Switch>
                <PrivateRoute
                  path="/login"
                  exact
                  authRoute
                  render={props => <Login {...props} />}
                />
                <Layout />
                {/* <Route path="/" component={props => <Layout {...props} />} /> */}
              </Switch>
            </div>
          </Sentry.ErrorBoundary>
        </div>
      </div>
    </ConfigProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  setAccessToken: token => dispatch(actions.setAccessToken(token)),
  onGetUserData: _ => dispatch(actions.getUserData()),
  getUserModel: () => dispatch(actions.getUserModel()),
  getAuthMeta: slug => dispatch(actions.getAuthMeta(slug)),
  exchangeToken: (slug, token) => dispatch(actions.exchangeToken(slug, token)),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
