import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ render, authRoute, ...rest }) => {
  const { accessToken } = useSelector(state => ({ accessToken: state.userReducer.accessToken }));
  const isAuth = localStorage.getItem('accessToken') || accessToken;
  return authRoute ? (
    <Route
      {...rest}
      render={props => (!isAuth
        ? render(props)
        : <Redirect to="/" />)}
    />
  ) : (
    <Route
      {...rest}
      render={props => (isAuth
        ? render(props)
        : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
