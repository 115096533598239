import React from 'react';

import styles from './FeedbackMessage.module.scss';

export default ({ type, message }) => {
  switch (type) {
  case 'error': {
    return (
      <div className={`${styles['validation-message']} ${styles.error}`}>
        <span>{message}</span>
      </div>
    );
  }
  default: return null;
  }
};
